import React from "react";

import { Icon } from "@chakra-ui/react";
import {
  MdHome,
  MdLock,
  MdDashboard,
  MdSports,
} from "react-icons/md";

// Admin Imports
import MainDashboard from "views/admin/default";

// Auth Imports
import SignInCentered from "views/auth/signIn";
import Sports from "views/admin/sportsClub";
import TeamConditionalComponent from "views/admin/Team";

let routes = [
  {
    name: "Sports Club",
    layout: "/admin",
    path: "/default",
    icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
    component: Sports,
  },
  // {
  //   name: "Programs",
  //   layout: "/admin",
  //   path: "/programs",
  //   icon: (
  //     <Icon
  //       as={MdDashboard}
  //       width='20px'
  //       height='20px'
  //       color='inherit'
  //     />
  //   ),
  //   component: UserPrograms,
  //   secondary: true,
  // },
  {
    name: "Stepathon",
    layout: "/admin",
    path: "/sportsClub",
    icon: (
      <Icon
        as={MdDashboard}
        width='20px'
        height='20px'
        color='inherit'
      />
    ),
    component: MainDashboard,
  },
  {
    name: "Sign Out",
    layout: "/auth",
    path: "/sign-in",
    icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
    component: SignInCentered,
    hide:true,
  },
  {
    name: "My Team",
    layout: "/admin",
    path: "/Team",
    icon: <Icon as={MdSports} width='20px' height='20px' color='inherit' />,
    component: TeamConditionalComponent,
  }

  // {
  //   name: "Health",
  //   layout: "/admin",
  //   path: "/health",
  //   icon: (
  //     <Icon
  //       as={BsFillHeartPulseFill}
  //       width='20px'
  //       height='20px'
  //       color='inherit'
  //     />
  //   ),
  //   //component: NFTMarketplace,
  //   secondary: true,
  // },
  // {
  //   name: "Community",
  //   layout: "/admin",
  //   path: "/community",
  //   icon: (
  //     <Icon
  //       as={IoIosPeople}
  //       width='20px'
  //       height='20px'
  //       color='inherit'
  //     />
  //   ),
  //   //component: NFTMarketplace,
  //   secondary: true,
  // },
  // {
  //   name: "Marketplace",
  //   layout: "/admin",
  //   path: "/nft-marketplace",
  //   icon: (
  //     <Icon
  //       as={MdOutlineShoppingCart}
  //       width='20px'
  //       height='20px'
  //       color='inherit'
  //     />
  //   ),
  //   //component: UserPrograms,
  //   secondary: true,
  // },
  // {
  //   name: "Reports",
  //   layout: "/admin",
  //   icon: <Icon as={MdBarChart} width='20px' height='20px' color='inherit' />,
  //   path: "/reports",
  //   //component: DataTables,
  // },
  // {
  //   name: "Profile",
  //   layout: "/admin",
  //   path: "/profile",
  //   icon: <Icon as={MdPerson} width='20px' height='20px' color='inherit' />,
  //   //component: Profile,
  // },
];

// if (userRole === "true") {
//   routes = [
//     ...routes,
//     {
//       name: "My Team",
//       layout: "/admin",
//       path: "/Team",
//       icon: <Icon as={MdSports} width='20px' height='20px' color='inherit' />,
//       component: Teams,
//     }
//   ];
// }

export default routes;
