import React, { createContext, useState, useContext } from 'react';

const AlertContext = createContext();

export const useAlert = () => useContext(AlertContext);

export const AlertProvider = ({ children }) => {
  const [alert, setAlert] = useState({ isOpen: false, content: null });

  const showAlert = (content) => setAlert({ isOpen: true, content });
  const hideAlert = () => setAlert({ isOpen: false, content: null });

  return (
    <AlertContext.Provider value={{ alert, showAlert, hideAlert }}>
      {children}
    </AlertContext.Provider>
  );
};
