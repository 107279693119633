import React from "react";

// Chakra imports
import {
  Flex,
  HStack,
  useColorModeValue,
  Image,
  Box,
  Text,
} from "@chakra-ui/react";
import imag from "../../../assets/img/avatars/avatar.jpg";
import { Icon } from "@chakra-ui/react";
import { FaCoins } from "react-icons/fa";

// Custom components
import { HorizonLogo } from "components/icons/Icons";
import { HSeparator } from "components/separator/Separator";

export function SidebarBrand() {
  //   Chakra color mode
  let logoColor = useColorModeValue("navy.700", "white");

  return (
    <Flex align="left" direction="column">
      <HorizonLogo color={logoColor} />

      <HStack mb={5} mt={2}>
        <Image src={localStorage.getItem('avatar')} w={20} h={20} borderRadius={80} />
        <Box>
          <Text fontWeight={"semibold"}>
            {localStorage.getItem("firstname")} {localStorage.getItem("lastName")}
          </Text>
          <Text fontSize={"sm"} textColor={"gray.500"} mb={1}>
            {localStorage.getItem('gender')} {localStorage.getItem('city')}
          </Text>
          <HStack>
            <Icon color={"orange"} as={FaCoins}></Icon>
            <Text fontSize={"sm"} fontWeight={"semibold"}>
              {localStorage.getItem('balance')} ~ ₹{parseInt(localStorage.getItem('balance')) / 5}
            </Text>
          </HStack>
        </Box>
      </HStack>
      <HSeparator mb="20px" />
    </Flex>
  );
}

export default SidebarBrand;
