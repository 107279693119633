/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useEffect } from 'react';
import {
    Box, Image, Badge, Text, Stack, Flex, useColorModeValue,
    Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter,
    ModalBody, ModalCloseButton, Tabs, TabList, TabPanels,
    Tab, TabPanel, Button, VStack, HStack, useBreakpointValue,
    Icon
} from '@chakra-ui/react';
import { FaCalendarAlt, FaClock, FaTag } from 'react-icons/fa';
import { useState } from 'react';
import { FaPhone } from 'react-icons/fa';
import axios from 'axios';
import { useAlert } from 'context/alertContext';
import { getUserDetailsHandler } from 'api/userServices';
import { parse, format, isToday, isTomorrow, isValid } from 'date-fns';
import useCreditorValidity from 'isuserCreditor';

const SportsCard = ({ id, imageUrl, title, description, venueCordinates, location, totalPlayers, seatsLeft, eventDetails, mobile, cordinatorname, isRegistered, setBid, isHidden, paymentBalance, gameType,challengerZone,eventName }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { eventImage, moreDetails, date, time, timeTo, price, } = eventDetails;
    const borderColor = useColorModeValue('gray.200', 'gray.700');
    const ethColor = useColorModeValue('orange.400', 'white');
    const [balance, setBalance] = useState(localStorage.getItem('balance'));
    const isBalanceLow = parseInt(balance) < price * 5;
    const [showReceipt, setShowReceipt] = useState(false);
    const [paymentDetails, setPaymentDetails] = useState({});
    const [paymentFailed, setPaymentFailed] = useState(false);
    const { showAlert } = useAlert();
    const objectFit = useBreakpointValue({ base: 'contain', md: 'cover' });
    const isValidCreditor = useCreditorValidity();


    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    const handleAddBalance = () => {
        const userData = {
            phone: localStorage.getItem('mobile'),
            amount: price,
            user_id: localStorage.getItem('userid'),
            game_id: id
        };

        createRazorpayOrder(userData);
    };

    const createRazorpayOrder = async (data) => {
        try {
            const orderResponse = await fetch(
                'https://go.mhealth.ai/api/PUBLIC/v1.0/razorpay/createOrder',
                // 'http://localhost:8080/api/PUBLIC/v1.0/razorpay/createOrder',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        mobile_number: data.phone,
                        amount: parseInt(data.amount) * 100,
                    }),
                }
            );

            if (orderResponse.ok) {
                const orderData = await orderResponse.json();
                initiateRazorpayPayment(orderData.order_id, parseInt(data.amount), data);
            } else {
                console.error('Order creation failed:', orderResponse.status);
            }
        } catch (error) {
            console.error('Error creating Razorpay order:', error);
        }
    };

    const initiateRazorpayPayment = (orderId, price, data) => {
        const script = document.createElement('script');
        script.src = 'https://checkout.razorpay.com/v1/checkout.js';
        script.async = true;
        script.onload = () => {
            const options = {
                key: 'rzp_live_7XrjCuJx0bpErh',
                //key: 'rzp_test_3znimJFTrLqAjz',
                amount: price * 100,
                currency: 'INR',
                name: 'mHealth.ai',
                description: 'Buying MCOINS',
                image: 'https://static.wixstatic.com/media/6d3641_590b79906a6c41cfa0179b6e3eb79262~mv2.png/v1/fill/w_226,h_226,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/Copy%20of%20mhealth%20logos%20(33).png', // Replace with your company logo
                order_id: orderId,
                handler: async (response) => {
                    if (response.razorpay_payment_id) {
                        console.log('Payment success:', response);
                        const verified = await verifyPayment(response.razorpay_payment_id, data);
                        if (verified) {
                            setPaymentDetails({
                                orderId: response.razorpay_order_id,
                                paymentId: response.razorpay_payment_id,
                                phoneNumber: localStorage.getItem('mobile')
                            });
                            setShowReceipt(true);
                            showAlert({
                                title: "Success",
                                description: "Payment Sucessfull",
                                status: "success",
                            });
                            window.location.reload();
                        } else {
                            console.error('Payment verification failed');
                        }
                    } else {
                        console.log('Payment failed:', response);
                        setPaymentFailed(true);
                        showAlert({
                            title: "Payment Failed",
                            description: "Payment Failed! try again.",
                            status: "error",
                        });
                    }
                },
                prefill: {
                    contact: data.phone,
                },
            };
            const razorpay = new window.Razorpay(options);
            razorpay.open();
        };
        document.head.appendChild(script);
    };


    const verifyPayment = async (paymentId, data) => {
        try {
            const response = await fetch('https://go.mhealth.ai/api/PUBLIC/v1.0/razorpay/verifyPayment', {
                // const response = await fetch('http://localhost:8080/api/PUBLIC/v1.0/razorpay/verifyPayment', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    id_mst_user: parseInt(localStorage.getItem('userid')),
                    id_mst_match: parseInt(id),
                    payment_id: paymentId,
                }),
            });
            getUserDetailsHandler().then((response) => {
                if (response.data.response.responseMessage === "SUCCESS") {
                    localStorage.setItem('balance', response.data.response.responseData.totalMHealthPoints);
                    setBalance(response.data.response.responseData.totalMHealthPoints);
                }
            })
            return response.ok;
        } catch (error) {
            return false;
        }
    };


    const convertTime12hrFormat = (timeString) => {
        const time = timeString.split(':');
        const hours = parseInt(time[0], 10);
        const minutes = time[1];
        const suffix = hours >= 12 ? "PM" : "AM";
        const newHours = ((hours + 11) % 12 + 1);
    
        return `${newHours}:${minutes} ${suffix}`;
    };
    

    useEffect(() => {
        getUserDetailsHandler().then((response) => {
            if (response.data.response.responseMessage === "SUCCESS") {
                localStorage.setItem('balance', response.data.response.responseData.totalMHealthPoints);
                setBalance(response.data.response.responseData.totalMHealthPoints);
            }
        })

    }, []);



    const formatDateTime = (dateString, timeString, timeToString) => {
        const date = parse(dateString, 'M/d/yyyy', new Date());
        const startTime = parse(convertTime12hrFormat(timeString), 'h:mm aa', date);
        const endTime = parse(convertTime12hrFormat(timeToString), 'h:mm aa', date);
    
        // Check if startTime and endTime are valid dates
        if (!isValid(startTime) || !isValid(endTime)) {
            console.error('Invalid startTime or endTime:', startTime, endTime);
            // Fallback message or logic
            return 'Time not available'; // Example fallback message
        }
    
        const formattedStartTime = format(startTime, 'h:mm aa');
        const formattedEndTime = format(endTime, 'h:mm aa');
    
        // Check if the date is today or tomorrow
        if (isToday(date)) {
            return `Today | ${formattedStartTime} - ${formattedEndTime}`;
        } else if (isTomorrow(date)) {
            return `Tomorrow | ${formattedStartTime} - ${formattedEndTime}`;
        } else {
            return `${format(date, 'EEE d MMM')} | ${formattedStartTime} - ${formattedEndTime}`;
        }
    };



    // console.log("Is Valid Creditor:", isValidCreditor);



    return (
        <><Box
            maxW="sm"
            borderWidth="1px"
            borderRadius="lg"
            overflow="hidden"
            borderColor={borderColor}
            position={"relative"}
            _hover={{ boxShadow: "md" }}
        >
           <Box position="relative">
        <Image
            src={imageUrl}
            alt={`Image of ${title}`}
            height="200px"
            width="100%"
            objectFit={objectFit}
            onClick={openModal}
        />
        {/* Overlay Details positioned at the bottom of the Image */}
        <Box
            position="absolute"
            bottom="0" // Ensures it's at the bottom of the *image*
            left="0"
            right="0"
            background="rgba(0, 0, 0, 0.5)" // Semi-transparent background
            color="white"
            padding="2"
        >
            <Text fontWeight="bold" fontSize="md">{challengerZone}</Text>
            <Text fontSize="sm">{eventName}</Text>
        </Box>
    </Box>

            <Box p="5">
                <Flex alignItems="baseline" justifyContent="space-between">
                    <Badge borderRadius="full" px="2" colorScheme={isHidden ? "red" : "linkedin"}>
                        {isHidden ? "Over" : "New"}
                    </Badge>
                    <Text onClick={openModal} color="gray.500" fontSize="sm">
                        {location}
                    </Text>
                </Flex>

                <Flex alignItems="baseline" justifyContent="space-between">
                    <Text
                        mt="2"
                        fontSize="xl"
                        fontWeight="semibold"
                        lineHeight="short"
                        onClick={openModal}
                        color={useColorModeValue('gray.700', 'white')}
                    >
                        {title}
                    </Text>
                    <Text textColor={'black'} onClick={openModal} fontSize="xs" color="gray.500">
                        <strong>{formatDateTime(date, time, timeTo)}</strong>
                    </Text>
                </Flex>

                <Text onClick={openModal} mt="2" color="gray.600" fontSize="sm">

                    {description}
                </Text>
                {!isHidden && (
                    <Stack direction="row" mt="4" alignItems="center" justifyContent="space-between">
                        <Text onClick={openModal} fontSize="sm" color="gray.500">
                            {totalPlayers} players
                        </Text>
                        {
                            (!isRegistered &&
                                ((gameType === 'INDIVIDUAL') ||
                                    (isValidCreditor && ['LEAGUE', 'FACEOFF'].includes(gameType)))) ? (
                                <Button onClick={() => setBid(id, price)} colorScheme="blue" alignSelf="end">₹{price} Book</Button>
                            ) : null
                        }
                        {
                            (isRegistered && isBalanceLow) ? (
                                <Stack direction="column" spacing={2}>
                                    <Text fontSize="sm" color="red.500">
                                        Low Balance! Please add balance to confirm your slot.
                                    </Text>
                                    <Button onClick={handleAddBalance} colorScheme="blue" alignSelf="end">Add ₹{price}</Button>
                                </Stack>
                            ) : null
                        }
                        {
                            (isRegistered && !isBalanceLow && !['LEAGUE', 'FACEOFF'].includes(gameType)) ? (
                                <Button disabled colorScheme="blue" alignSelf="end">Slot Locked</Button>
                            ) : null
                        }
                    </Stack>
                )}




            </Box>
        </Box><Modal isOpen={isModalOpen} onClose={closeModal} size="xl">
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{title}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Tabs isFitted variant="enclosed">
                            <TabList mb="1em">
                                <Tab>Overview</Tab>
                                <Tab>List of Players</Tab>
                                <Tab>Venue Details</Tab>
                            </TabList>
                            <TabPanels>
                                <TabPanel>
                                    <VStack spacing={4} align="start">
                                        <Image src={eventImage} alt={`Image of ${title}`} borderRadius="md" />
                                        <Text fontSize="md">{moreDetails}</Text>

                                        <Flex justify="space-between" width="100%">
                                            <HStack>
                                                <Icon as={FaCalendarAlt} w={3} h={3} />
                                                <Text fontSize="sm"><strong>Date:</strong> {date}</Text>
                                            </HStack>
                                            <HStack>
                                                <Icon as={FaClock} w={3} h={3} />
                                                <Text fontSize="sm">
                                                    <strong>Time:</strong> {convertTime12hrFormat(time)}
                                                </Text>
                                            </HStack>
                                        </Flex>

                                        <Flex justify="space-between" width="100%">

                                            <HStack>
                                                <Icon as={FaTag} w={3} h={3} />
                                                <Text fontSize="sm"><strong>Cordinator:</strong> {cordinatorname}</Text>
                                            </HStack>
                                            <HStack>
                                                <Icon as={FaPhone} w={3} h={3} />
                                                <Text fontSize="sm"><strong>Phone:</strong> {mobile}</Text>
                                            </HStack>
                                        </Flex>
                                    </VStack>
                                </TabPanel>

                                <TabPanel>
                                    <Box textAlign="center" p={5}>
                                        <Text fontSize="lg" color={useColorModeValue('gray.700', 'white')}>
                                            Stay Tuned!
                                        </Text>
                                        <Text fontSize="md">
                                            The list of players will be shared 3 hours prior to the match. Keep an eye out for updates and get ready to meet your teammates!
                                        </Text>
                                    </Box>
                                </TabPanel>

                                <TabPanel>
                                    <Box>
                                        <iframe
                                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3507.773164158785!2d77.0866331759181!3d28.456253575761494!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d197f43deb879%3A0xa7a91510a138aa9b!2sSPADA%20Arenas%20-%20Football%2C%20Basketball%20%26%20Box%20Cricket%20in%20Gurgaon!5e0!3m2!1sen!2sin!4v1707104938119!5m2!1sen!2sin"
                                            width="100%"
                                            height="450"
                                            style={{ border: 0 }}
                                            allowFullScreen=""
                                            loading="lazy"
                                            referrerPolicy="no-referrer-when-downgrade"
                                        ></iframe>
                                    </Box>
                                </TabPanel>
                            </TabPanels>
                        </Tabs>
                    </ModalBody>
                    {!isHidden && (
                        <ModalFooter>
                            {(!isRegistered && gameType === 'INDIVIDUAL') ? (
                                <Button onClick={() => setBid(id, price)} colorScheme="blue" alignSelf="end">₹{price} Book</Button>
                            ) : (!isRegistered && ['LEAGUE', 'FACEOFF'].includes(gameType) && isValidCreditor) ? (
                                <Button onClick={() => setBid(id, price)} colorScheme="blue" alignSelf="end">₹{price} Book</Button>
                            ) : isRegistered && isBalanceLow ? (
                                <>
                                    <Text fontSize="sm" color="red.500" mr={3}>
                                        Low Balance! Please add balance to confirm your slot.
                                    </Text>
                                    <Button onClick={handleAddBalance} colorScheme="blue" alignSelf="end">Add ₹{price}</Button>
                                </>
                            ) : isRegistered && !isBalanceLow && (
                                <Button disabled colorScheme="blue" alignSelf="end">Slot Locked</Button>
                            )}
                        </ModalFooter>
                    )}


                </ModalContent>
            </Modal>
        </>
    );
};

export default SportsCard;
