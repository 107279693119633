import React, { useEffect, useState } from 'react';
import axios from 'axios';
import SportsCard from './sportsCard';
import Card from 'components/card/Card';
import { Box, Text, Flex, useColorModeValue, Button, useDisclosure, useBreakpointValue, Spinner, SimpleGrid, VStack, RadioGroup, Stack, Radio } from '@chakra-ui/react';
import { useAlert } from 'context/alertContext';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Select } from '@chakra-ui/react';
import { FaGift } from 'react-icons/fa';
import { parse } from 'stylis';
import UserForm from './addPlayer';
import ReferralComponent from 'components/referall/referallCard';
import { Tab, Tabs, TabList, TabPanel, TabPanels } from '@chakra-ui/react';
import { FaTrophy, FaUserFriends, FaRegHandshake } from 'react-icons/fa';

const Sports = () => {
  const [allMatches, setAllMatches] = useState([]);
  const [upcomingMatches, setUpcomingMatches] = useState([]);
  const [visibleUpcomingMatches, setVisibleUpcomingMatches] = useState([]);
  const [visibleAllMatches, setVisibleAllMatches] = useState([]);
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const tabIndicatorColor = useColorModeValue('blue.500', 'blue.200');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isSubscribed, setIsSubscribed] = useState(true);
  const bgColor = useColorModeValue('gray.50', 'gray.700');
  const cardBgColor = useColorModeValue('white', 'gray.800');
  const boxShadow = useColorModeValue('md', 'dark-lg');
  const [selectedSport, setSelectedSport] = useState('cricket');
  const [isLoading, setIsLoading] = useState(false);
  const [selectedMatchType, setSelectedMatchType] = useState('LEAGUE'); // Default match type



  const { showAlert } = useAlert();


  const fetchSubscriptionStatus = async () => {
    const config = {
      method: 'get',
      url: `https://api.mhealth.ai/clients/getAllEvents?others=walkathon21&userId=${localStorage.getItem('userid')}`,
      headers: {
        'Accept': '*/*',
        'Authorization': `Bearer VAP3sABNy-xCcJUvmvH1cWLeugP3VvzcGp2T5vD_6EA`
      }
    };

    try {
      const response = await axios(config);
      const events = response.data.response.responseData.events;
      let isSubscribedToSpecificEvents = false;

      // Check if any of the specific event IDs are present
      const specificEventPresent = events.some(event => event.id === 86 || event.id === 87);

      isSubscribedToSpecificEvents = specificEventPresent;

      setIsSubscribed(isSubscribedToSpecificEvents);
    } catch (error) {
      console.error('Error fetching subscription status:', error);
      setIsSubscribed(false);
    }
  };

  useEffect(() => {
    fetchSubscriptionStatus();
  }, []);



  const handleRegisterClick = async () => {
    const challengerZoneIds = selectedSport === 'both' ? [86, 87] : [selectedSport === 'cricket' ? 86 : 87];
    setIsLoading(true);


    try {
      await Promise.all(challengerZoneIds.map(challengerZoneId =>
        axios.post('https://api.mhealth.ai/v1.0/registerInEvent', {
          eventId: challengerZoneId,
          dataSource: "WHATSAPP",
          registrationSource: 'WEB',
        }, {
          headers: {
            'Accept': '*/*',
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          }
        })
      ));
      showAlert('success', 'Registration successful!');
      fetchSubscriptionStatus();
    } catch (error) {
      console.error('Registration failed:', error);
    } finally {
      setIsLoading(false);
    }
  };


  const fetchMatches = async () => {
    try {
      const response = await axios.get(`https://go.mhealth.ai/api/USER/v1.0/sportsClub/getAllMatches?id_mst_challengerzone=${localStorage.getItem('selectedClub')}`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });

      const currentDateTime = new Date();
      let fetchedMatches = response.data.map(match => ({
        ...match,
        isHidden: new Date(match.match.BookingEndsOn) < currentDateTime,
        isUpcoming: new Date(match.match.MatchDate) > currentDateTime,
      }));

      const upcomingMatches = fetchedMatches.filter(match => match.isUpcoming && !match.isHidden);
      const visibleUpcoming = upcomingMatches.filter(match => !match.isHidden);
      const visibleAll = fetchedMatches.filter(match => match.isHidden);



      setAllMatches(visibleAll);
      setUpcomingMatches(upcomingMatches);
      setVisibleUpcomingMatches(visibleUpcoming);
      setVisibleAllMatches(fetchedMatches.filter(match => !match.isHidden));
    } catch (error) {
      console.error("Error fetching matches:", error);
    }
  };

  useEffect(() => {
    fetchMatches();
  }, []);

  const renderMatches = (matches) => {
    const filteredMatches = matches.filter(match => match.match.MatchType === selectedMatchType);
    return filteredMatches.map(match => (
      <SportsCard
        setBid={handleSetBid}
        key={match.match.IDMstMatches}
        id={match.match.IDMstMatches}
        imageUrl="https://preview.redd.it/football-in-gurgaon-the-best-kept-secret-v0-20c2n2dgdf8a1.jpg?width=1000&format=pjpg&auto=webp&s=e465738d46a357eb8156e1de94e0a8e484870e7a"
        title={match.match.MatchType + " GAME"}
        description={match.match.MatchDesc}
        location={match.match.Venue}
        totalPlayers={match.match.TotalPlayers}
        seatsLeft={match.match.PaymentPerSeat}
        mobile={match.match.CoordinatorMobile}
        cordinatorname={match.match.MatchCoordinator}
        venueCordinates={match.match.VenueCoordinates}
        isRegistered={match.isRegistered}
        gameType={match.match.MatchType}
        isHidden={match.isHidden}
        paymentBalance={match.paymentBalance}
        challengerZone= {match.ChallengerZoneName}
        eventName={match.SubChallengerZoneName}
        eventDetails={{
          eventImage: 'https://preview.redd.it/football-in-gurgaon-the-best-kept-secret-v0-20c2n2dgdf8a1.jpg?width=1000&format=pjpg&auto=webp&s=e465738d46a357eb8156e1de94e0a8e484870e7a',
          moreDetails: `${match.match.MatchDesc}`,
          date: new Date(match.match.MatchDate).toLocaleDateString(),
          time: match.match.MatchTimeFrom,
          timeTo: match.match.MatchTimeTo,
          price: `${match.match.PaymentPerSeat}`,
        }}
      />
    ));
  };

  const handleSetBid = (idmatch, price) => {
    const authToken = localStorage.getItem("token");
    const currentDateTime = new Date();
    const formattedDateTime = currentDateTime
      .toISOString()
      .replace("T", " ")
      .substring(0, 19);

    let userBalance;
    const balance = parseInt(localStorage.getItem('balance') / 5);
    const Intprice = parseInt(price);

    if (balance < Intprice) {
      userBalance = "INSUFFICIENT"
    } else {
      userBalance = "AVAILABLE"
    }

    axios
      .post(
        "https://go.mhealth.ai/api/INTERNAL/v1.0/matches/setBid",
        {
          id_mst_users: parseInt(localStorage.getItem('userid')),
          id_mst_matches: idmatch,
          bid_accepted_on: {
            Time: formattedDateTime,
            Valid: true

          },
          payment_balance: userBalance
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      )
      .then((response) => {
        fetchMatches();
      })

      .catch((error) => {
        fetchMatches();
      });
  };

  if (isLoading) {
    return <Spinner color="blue.500" size="xl" />;
  }

  const matchTypes = ['LEAGUE', 'FACEOFF', 'INDIVIDUAL'];



  if (!isSubscribed) {
    return (
      <Box
        pt={{ base: "130px", sm: "100px", md: "80px", xl: "80px" }}
        bg={bgColor}
        minH="100vh"
        px={{ base: 4, sm: 6, md: 8 }}
      >
        <Box
          maxW="xl"
          mx="auto"
          bg={cardBgColor}
          boxShadow={boxShadow}
          rounded="lg"
          p={{ base: 4, sm: 6, md: 8 }}
        >
          <Text
            fontSize={{ base: "2xl", md: "3xl" }}
            fontWeight="bold"
            textAlign="center"
            mb={4}
          >
            Welcome To mHealth Sports Club
          </Text>
          <VStack spacing={4} align="stretch">
            <Text fontSize="lg">Please select your favorite sport:</Text>
            <RadioGroup onChange={setSelectedSport} value={selectedSport}>
              <Stack direction="column">
                <Radio value="cricket" size="lg">Cricket</Radio>
                <Radio value="football" size="lg">Football</Radio>
                <Radio value="both" size="lg">Both</Radio>
              </Stack>
            </RadioGroup>
            <Button
              colorScheme="blue"
              size="lg"
              mt={4}
              _hover={{ bg: "blue.600" }}
              onClick={handleRegisterClick}
            >
              Register Now
            </Button>
          </VStack>
        </Box>
      </Box>
    );

  } else {
    return (
      <Box pt={{ base: "130px", sm: "80px", md: "80px", xl: "80px" }}>

        <Card>
          <Tabs onChange={(index) => setSelectedMatchType(['LEAGUE', 'FACEOFF', 'INDIVIDUAL'][index])} isLazy variant="enclosed" colorScheme="blue">
            <TabList mb="1em">
              <Tab
                _selected={{ color: 'white', bg: tabIndicatorColor }}
                _focus={{ shadow: 'none' }}
              >
                <FaTrophy />
                <Text ml={2}>Leagues</Text>
              </Tab>
              <Tab
                _selected={{ color: 'white', bg: tabIndicatorColor }}
                _focus={{ shadow: 'none' }}
              >
                <FaUserFriends />
                <Text ml={2}>Faceoff</Text>
              </Tab>
              <Tab
                _selected={{ color: 'white', bg: tabIndicatorColor }}
                _focus={{ shadow: 'none' }}
              >
                <FaRegHandshake />
                <Text ml={2}>Individual</Text>
              </Tab>
            </TabList>
            <TabPanels>
              {/* Tab panels */}
            </TabPanels>
          </Tabs>
          <Modal colorScheme='blue' size='2xl' isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
              <ModalCloseButton />
              <ModalBody>
                <ReferralComponent />
              </ModalBody>
              <ModalFooter>
              </ModalFooter>
            </ModalContent>
          </Modal>
          <Flex justify="space-between" align="center" mb={3} >
            <Text color={textColor} fontWeight="700" lineHeight="100%">
              Upcoming Matches
            </Text>
            <Button colorScheme='blue' leftIcon={<FaGift />} onClick={onOpen}>
              Refer & Earn
            </Button>
          </Flex>
          <SimpleGrid columns={{ base: 1, sm: 1, md: 2, lg: 3, xl: 3 }} spacing={2}>
            {renderMatches(upcomingMatches)}
          </SimpleGrid>
        </Card>
        <Box pt={{ base: "40px", md: "30px", xl: "20px" }}>
          <Card>
            <Flex justify="space-between" align="center" mb={3}>
              <Text color={textColor} fontSize="22px" fontWeight="700" lineHeight="100%">
                All Matches
              </Text>
            </Flex>
            <SimpleGrid columns={{ base: 1, sm: 1, md: 2, lg: 3, xl: 3 }} spacing={2}>
              {renderMatches(allMatches)}
            </SimpleGrid>
          </Card>
        </Box>


      </Box>
    );
  };
}

export default Sports;
