// useCreditorValidity.js
import { useEffect, useState } from 'react';
import axios from 'axios';

const useCreditorValidity = (mobileNumber) => {
    const [isValidCreditor, setIsValidCreditor] = useState(false);

    useEffect(() => {
        const checkCreditorValidity = async () => {
            try {
                const response = await axios.get(`https://go.mhealth.ai/api/INTERNAL/v1.0/sportsClub/isValidCreditor?mobile_number=${localStorage.getItem('mobile')}`);
                setIsValidCreditor(response.data.isValid);
                console.log("Creditor validity:", response.data.isValid);
                localStorage.setItem('isCreditor', response.data.isValid);
                localStorage.setItem('creditorID', response.data.creditorId);
            } catch (error) {
                console.log("Error checking creditor validity:", error);
            }
        };

        checkCreditorValidity();
    }, [mobileNumber]);

    return isValidCreditor;
};

export default useCreditorValidity;
