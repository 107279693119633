/* eslint-disable */

import React, { useState,useEffect } from "react";
import { NavLink, useHistory } from "react-router-dom";
// Chakra imports
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import { HSeparator } from "components/separator/Separator";
import DefaultAuth from "layouts/auth/Default";
// Assets
import loginbanner from "assets/img/auth/auth.jpg";
import { FcGoogle } from "react-icons/fc";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import ApiCalls from "api/customAxios";
import { sendOtp, validateUser, verifyOtp, registerUser, loginUser } from "api/collection";
import axios from "axios";
import { Spinner } from "@chakra-ui/react";
import { useAlert } from "context/alertContext";
import { useAuth } from "context/authContext";
import { urlPrefix } from "api/collection";

function SignIn() {
  // Chakra color mode
  const textColor = useColorModeValue("navy.700", "white");
  const { login } = useAuth();
  const textColorSecondary = "gray.400";
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const { showAlert } = useAlert();
  const googleBg = useColorModeValue("secondaryGray.300", "whiteAlpha.200");
  const googleText = useColorModeValue("navy.700", "white");
  const googleHover = useColorModeValue(
    { bg: "gray.200" },
    { bg: "whiteAlpha.300" }
  );
  const googleActive = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.200" }
  );
  const [show, setShow] = React.useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const history = useHistory();
  const handleClick = () => setShow(!show);
  const [logIndata, setLogInData] = React.useState({});
  const [step, setStep] = React.useState({
    loginStep: false,
    signUpStep: false,
  });
  const [detailsFetched, setDetailsFetched] = useState(false);
  const [shouldRedirect, setShouldRedirect] = useState(false);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const token = queryParams.get('token');
    const userId= queryParams.get('userId');
    const fetchUserDetails = async (token) => {
      try {
        const response = await axios.post(
          'https://api.mhealth.ai/v1.0/getUserDetails',
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
              // Other headers...
            },
          }
        );
  
        const userDetailsData = response.data?.response?.responseData;
        localStorage.setItem('avatar', userDetailsData.avtarImg || "https://upload.wikimedia.org/wikipedia/commons/8/89/Portrait_Placeholder.png");
        localStorage.setItem('balance', userDetailsData.totalMHealthPoints);
        localStorage.setItem('mobile', userDetailsData.mobileNumber)
        localStorage.setItem('firstname', userDetailsData.firstName);
        localStorage.setItem('city', userDetailsData.city);
        localStorage.setItem('lastName', userDetailsData.lastName);
        localStorage.setItem('gender', userDetailsData.gender);
        localStorage.setItem('authorizedDatasource', JSON.stringify(userDetailsData.authorizedDatasource));
        localStorage.setItem('selectedClub', (userDetailsData.defaultEvent || "10"));
        localStorage.setItem('userid', userId);
  
        login(token, {
          userId: userId, 
          userType: userDetailsData.userType,
        });
        setDetailsFetched(true);
        setShouldRedirect(true);
      } catch (error) {
        console.error(error);
      }
    };

    if (token && !detailsFetched) {
      fetchUserDetails(token);
    }
  }, [login, detailsFetched]);

  useEffect(() => {
    if (shouldRedirect) {
      history.push('/admin/default');
    }
  }, [shouldRedirect, history]);

  const handleInputChange = (e) => {
    setLogInData({ ...logIndata, [e.target.name]: e.target.value });
  };
  const exists = async () => {
    setIsLoading(true);
    try {
      const response = await ApiCalls.get(`${validateUser}?mobileNumber=${logIndata.phone}&countryCode=91`);
      if (response.status === 'ACTIVE') {
        setStep({ loginStep: true, signUpStep: false });
        logIn();
      } else {
        showAlert({
          title: "Failed!",
          description: "User Not Found",
          status: "error",
        });
        const mobile = encodeURIComponent(logIndata.phone);
        const redirectPath = encodeURIComponent('/redirectToApp');
        const domain= 'mhealth.ai'
        window.location.href = `http://${domain}/signup?mobile=${mobile}&redirect=${redirectPath}`;

        setIsLoading(false);
      }
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };


  const logIn = async () => {
    setIsLoading(true);
    const clientId = 'Walkathon21';
    const clientSecret = 'b189505ccb4f4c83fe2ef7939dc34a887738206df24bdad69779356688566279';
    const loginUrl = 'https://api.mhealth.ai/get/token';
    const headers = {
      accept: '*/*',
      clientId,
      clientSecret,
      mobileNumber: logIndata.phone,
      password: logIndata.password,
      'Content-Type': 'application/json',
    };

    try {
      const response = await axios.get(loginUrl, { headers });
      const responseData = response.data.responseData;
      const responseCode = response.data.responseCode;
      if (responseCode === 0) {
        const { authToken, userId, userType } = response.data.responseData;
        localStorage.setItem('userid', userId);
        localStorage.setItem('token', authToken);
        localStorage.setItem('userType', userType);
        login(authToken, { userId, userType });
        await fetchUserDetails(authToken);
        history.push("/admin/default");

        showAlert({
          title: "Sucess",
          description: "Login sucessfull",
          status: "success",
        });
      } else {
        showAlert({
          title: "Login Failed",
          description: "you have entered incorrect pin",
          status: "error",
        });
        setIsLoading(false);
      }
    } catch (error) {
      console.error(error);
      showAlert({
        title: "Login Failed",
        description: "you have entered incorrect pin",
        status: "error",
      });
      setIsLoading(false);
    }
  };


  const fetchUserDetails = async (token) => {
    try {
      const response = await axios.post(
        'https://api.mhealth.ai/v1.0/getUserDetails',
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
            timeStamp: 'timestamp',
            accept: '*/*',
            'Access-Control-Allow-Origin': '*',
            withCredentials: true,
            'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,OPTIONS',
            'Access-Control-Allow-Headers':
              'accept, content-type, x-access-token, x-requested-with',
          },
        }
      );

      const userDetailsData = response.data?.response?.responseData;
      localStorage.setItem('avatar', userDetailsData.avtarImg || "https://upload.wikimedia.org/wikipedia/commons/8/89/Portrait_Placeholder.png");
      localStorage.setItem('balance', userDetailsData.totalMHealthPoints);
      localStorage.setItem('mobile', userDetailsData.mobileNumber)
      localStorage.setItem('firstname', userDetailsData.firstName);
      localStorage.setItem('city', userDetailsData.city);
      localStorage.setItem('lastName', userDetailsData.lastName);
      localStorage.setItem('gender', userDetailsData.gender);
      localStorage.setItem('authorizedDatasource', JSON.stringify(userDetailsData.authorizedDatasource));
      localStorage.setItem('selectedClub', (userDetailsData.defaultEvent || "10"));
      setIsLoading(false);
    } catch (error) {
      console.error(error);
    }
  };


  const signUp = () => {
    ApiCalls.post("v1.0/registerUser", logIndata)
      .then((res) => {
        localStorage.setItem("token", res.token);
        console.log(res.token);
      })
      .catch((err) => {
        console.log(err);
      });
  };


  return (
    <DefaultAuth illustrationBackground={loginbanner} image={loginbanner}>
      <Flex
        maxW={{ base: "100%", md: "max-content" }}
        w="100%"
        mx={{ base: "auto", lg: "0px" }}
        me="auto"
        h="100%"
        alignItems="start"
        justifyContent="center"
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "25px", md: "0px" }}
        mt={{ base: "40px", md: "14vh" }}
        flexDirection="column"
      >
        <Box me="auto">
          <Heading color={textColor} fontSize="36px" mb="10px">
            Get Started
          </Heading>
          <Text
            mb="36px"
            ms="4px"
            color={textColorSecondary}
            fontWeight="400"
            fontSize="md"
          >
            Enter your phone number
          </Text>
        </Box>
        <Flex
          zIndex="2"
          direction="column"
          w={{ base: "100%", md: "420px" }}
          maxW="100%"
          background="transparent"
          borderRadius="15px"
          mx={{ base: "auto", lg: "unset" }}
          me="auto"
          mb={{ base: "20px", md: "auto" }}
        >


          <FormControl>
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
            >
              Mobile No.<Text color={brandStars}>*</Text>
            </FormLabel>
            <Input
              isRequired={true}
              variant="auth"
              fontSize="sm"
              ms={{ base: "0px", md: "0px" }}
              type="mobile"
              placeholder="+91"
              mb="24px"
              maxLength={10}
              fontWeight="500"
              size="lg"
              name="phone"
              onChange={handleInputChange}
            />
            {step.signUpStep && (
              <>
                <FormLabel
                  display="flex"
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                  mb="8px"
                >
                  Full Name<Text color={brandStars}>*</Text>
                </FormLabel>
                <Input
                  isRequired={true}
                  variant="auth"
                  fontSize="sm"
                  ms={{ base: "0px", md: "0px" }}
                  //   type="mobile"
                  placeholder="enter your name"
                  mb="24px"
                  fontWeight="500"
                  size="lg"
                  name="name"
                  onChange={(e) => {
                    setLogInData({
                      ...logIndata,
                      first_name: e.target.value.split(" ")[0],
                      last_name: e.target.value
                        ? e.target.value.split(" ").slice(1).join(" ")
                        : "",
                    });
                  }}
                />
              </>
            )}
            {(step.signUpStep || step.loginStep) && (
              <>
                <FormLabel
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                  display="flex"
                >
                  PIN<Text color={brandStars}>*</Text>
                </FormLabel>
                <InputGroup size="md">
                  <Input
                    isRequired={true}
                    fontSize="sm"
                    maxLength={4}
                    placeholder="Max. 4 numbers"
                    mb="24px"
                    size="lg"
                    type={show ? "text" : "password"}
                    variant="auth"
                    onChange={(e) => {
                      setLogInData({ ...logIndata, password: e.target.value });
                    }}
                  />
                  <InputRightElement
                    display="flex"
                    alignItems="center"
                    mt="4px"
                  >
                    <Icon
                      color={textColorSecondary}
                      _hover={{ cursor: "pointer" }}
                      as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                      onClick={handleClick}
                    />
                  </InputRightElement>
                </InputGroup>
              </>
            )}
            <Flex justifyContent="space-between" align="center" mb="24px">
              <FormControl display="flex" alignItems="center">
                <Checkbox
                  id="remember-login"
                  colorScheme="brandScheme"
                  me="10px"
                />
                <FormLabel
                  htmlFor="remember-login"
                  mb="0"
                  fontWeight="normal"
                  color={textColor}
                  fontSize="sm"
                >
                  Keep me logged in
                </FormLabel>
              </FormControl>
              <NavLink to="/auth/forgot-password">
                <Text
                  color={textColorBrand}
                  fontSize="sm"
                  w="124px"
                  fontWeight="500"
                >
                  Forgot pin?
                </Text>
              </NavLink>
            </Flex>
            <Button
              fontSize="sm"
              variant="brand"
              fontWeight="500"
              w="100%"
              h="50"
              onClick={step.loginStep ? logIn : step.signUpStep ? signUp : exists}
              mb="24px"
              isLoading={isLoading}
              loadingText="Logging in..."
              disabled={isLoading}
            >
              Verify
            </Button>

          </FormControl>
        </Flex>
      </Flex>
    </DefaultAuth>
  );
}

export default SignIn;
