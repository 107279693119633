// src/context/AuthContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthenticationListener = () => {
  const history = useHistory();
  const { isAuthenticated } = useAuth();

  useEffect(() => {
    if (isAuthenticated) {
      // Redirect to the desired route upon login
      history.push('/admin/default');
    } else {
      history.push('/auth');
    }
  }, [isAuthenticated, history]);

  return null;
};

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(() => {
    const token = localStorage.getItem('token');
    const userDetails = localStorage.getItem('userDetails');
    return token ? { token, userDetails: JSON.parse(userDetails) } : null;
  });

  const login = (authToken, userDetails) => {
    localStorage.setItem('token', authToken);
    localStorage.setItem('userDetails', JSON.stringify(userDetails));
    setUser({ token: authToken, userDetails });
  };

  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('userDetails');
    setUser(null);
  };


  const updateUserDetails = (newDetails) => {
    const updatedDetails = { ...user.userDetails, ...newDetails };
    localStorage.setItem('userDetails', JSON.stringify(updatedDetails));
    setUser((currentUser) => ({ ...currentUser, userDetails: updatedDetails }));
  };

  useEffect(() => {
  }, [user]);

  const value = {
    user,
    isAuthenticated: !!user,
    login,
    logout,
    updateUserDetails,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
