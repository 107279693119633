import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  CloseButton,
} from '@chakra-ui/react';
import { useAlert } from 'context/alertContext';

const GlobalAlert = () => {
  const { alert, hideAlert } = useAlert();

  useEffect(() => {
    if (alert.isOpen) {
      const timer = setTimeout(() => {
        hideAlert();
      }, alert.content?.duration || 5000);

      return () => clearTimeout(timer);
    }
  }, [alert, hideAlert]);

  if (!alert.isOpen) return null;

  // Determine color scheme based on status
  const colorScheme = alert.content?.status === 'error' ? 'red' : 'blue';

  return ReactDOM.createPortal(
    <Alert
      borderRadius="8px"
      status={alert.content?.status}
      variant="solid"
      position="fixed"
      top="20px"
      right="20px"
      zIndex="tooltip"
      maxW="md"
      boxShadow="lg"
      colorScheme={colorScheme} // Set color scheme dynamically
    >
      <AlertIcon />
      <AlertTitle mr={2}>{alert.content?.title}</AlertTitle>
      <AlertDescription>{alert.content?.description}</AlertDescription>
      <CloseButton position="absolute" right="8px" top="8px" onClick={hideAlert} />
    </Alert>,
    document.getElementById('alert-portal')
  );
};

export default GlobalAlert;
