import React, { useState, useEffect } from 'react';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Text,
  VStack,
  Image,
  HStack,
  Flex,
  Spinner,
  useToast,
} from '@chakra-ui/react';
import { getAuthLink } from 'api/challenges';
import { getUserDetailsHandler } from 'api/userServices';
import { useAlert } from 'context/alertContext';


const dataSourceIcons = {
  WHATSAPP: "https://walkathon21.s3.ap-south-1.amazonaws.com/logo/whatsapp.svg",
  STRAVA: "https://walkathon21.s3.ap-south-1.amazonaws.com/logo/strava.svg",
  GOOGLE_FIT: "https://walkathon21.s3.ap-south-1.amazonaws.com/logo/googlefit.svg",
  FIT_BIT: "https://walkathon21.s3.ap-south-1.amazonaws.com/logo/fitbit.svg",
  APPLE: "https://walkathon21.s3.ap-south-1.amazonaws.com/logo/Apple_Health.svg",
  SAMSUNG: "https://walkathon21.s3.ap-south-1.amazonaws.com/logo/Samsung_Health.svg",
};

const ConnectDataSourceModal = ({ isOpen, onClose }) => {
  const [authorizedSources, setAuthorizedSources] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { showAlert } = useAlert();


  useEffect(() => {
    getUserDetailsHandler().then((response) => {
      if (response.data.response.responseMessage === "SUCCESS") {
        setAuthorizedSources(response.data.response.responseData.authorizedDatasource);
        setIsLoading(false);
      } else {
        showAlert({
          title: "Error",
          description: "Failed to fetch data sources.",
          status: "error",
        });
        setIsLoading(false);
      }
    }).catch(() => {
      setIsLoading(false);
     showAlert({
        title: "Error",
        description: "An error occurred while fetching data sources.",
        status: "error",
      });
    });
  }, [showAlert]);

  const handleDataSourceConnect = (dataSource) => {
    setIsLoading(true);
    getAuthLink(dataSource).then((res) => {
      const authUrl = res.data.response.responseData.authorizationLink;
      window.open(authUrl, '_blank');
      setIsLoading(false);
    }).catch((error) => {
      console.error("Error connecting to data source:", error);
      showAlert({
        title: "Connection Error",
        description: `Failed to connect to ${dataSource}.`,
        status: "error",
      });
      setIsLoading(false);
    });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Connect a Data Source</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {isLoading ? (
            <Flex justify="center">
              <Spinner />
            </Flex>
          ) : (
            <VStack spacing={4}>
              {authorizedSources.length > 0 ? authorizedSources.map((source) => (
                <HStack key={source.dataSource} justify="space-between" w="full">
                  <HStack>
                    <Image src={dataSourceIcons[source.dataSource]} boxSize="24px" />
                    <Text>{source.dataSource}</Text>
                  </HStack>
                  <Button
                    colorScheme={source.authorized ? "green" : "blue"}
                    onClick={() => handleDataSourceConnect(source.dataSource)}
                    isDisabled={source.authorized}
                  >
                    {source.authorized ? "Authorized" : "Connect"}
                  </Button>
                </HStack>
              )) : (
                <Text>No data sources available.</Text>
              )}
            </VStack>
          )}
        </ModalBody>
        <ModalFooter>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ConnectDataSourceModal;
