import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
    Box, Flex, Button, useDisclosure, VStack, HStack, Icon, Image, Table, Thead, Tbody, Tr, Th, Td, Text, Modal,
    ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, IconButton
} from '@chakra-ui/react';
import { MdMale, MdFemale, MdPhoneInTalk, MdRefresh, MdAdd } from 'react-icons/md';
import UserForm from './addPlayers';
import Card from 'components/card/Card';

const Teams = () => {
    const [players, setPlayers] = useState([]);
    const [selectedPlayer, setSelectedPlayer] = useState(null);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const detailModal = useDisclosure();

    const fetchPlayers = async () => {
        const creditorId = parseInt(localStorage.getItem('creditorID'), 10);
        const token = localStorage.getItem('token');
        try {
            const response = await axios.get(`https://go.mhealth.ai/api/INTERNAL/v1.0/getAllPlayersByCreditorId?id_mst_game_creditor=${creditorId}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Accept': 'application/json, text/plain, */*',
                    'Accept-Language': 'en-US,en;q=0.9',
                }
            });
            setPlayers(response.data);
        } catch (error) {
            console.error("Error fetching players:", error);
        }
    };

    useEffect(() => {
        fetchPlayers();
    }, []);

    const handlePlayerClick = (player) => {
        setSelectedPlayer(player);
        detailModal.onOpen();
    };

    return (
        <Box pt={{ base: "130px", sm: "80px", md: "80px", xl: "80px" }} bg="#f8f9fa">
            <Card>
                <Flex justify="space-between" align="center" mb={4}>
                    <Button leftIcon={<MdAdd />} onClick={onOpen} colorScheme='teal' variant="solid">
                        Add Player
                    </Button>
                    <IconButton icon={<MdRefresh />} onClick={fetchPlayers} colorScheme='teal' variant="outline" aria-label="Sync Players" />
                </Flex>
                <Table variant="simple" colorScheme="gray" size="md">
                    <Thead>
                        <Tr>
                            <Th>Name</Th>
                            <Th display={{ base: "table-cell", md: "none" }}>Role</Th>
                            <Th display={{ base: "none", md: "table-cell" }}>Role</Th>
                            <Th display={{ base: "none", md: "table-cell" }}>City</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {players.map((player, index) => (
                            <Tr key={index} cursor="pointer" _hover={{ bg: 'gray.100' }} onClick={() => handlePlayerClick(player)}>
                                <Td>
                                    <HStack spacing={4}>
                                        <Image
                                            borderRadius='full'
                                            boxSize={{ base: '30px', sm: '40px' }}
                                            src={player.avtar_img || 'https://cdn-icons-png.flaticon.com/512/149/149071.png'}
                                            alt={`${player.first_name} ${player.last_name}`}
                                            fallbackSrc='https://cdn-icons-png.flaticon.com/512/149/149071.png'
                                        />
                                        <Text fontSize={{ base: "sm", md: "md" }}>{player.first_name} {player.last_name}</Text>
                                    </HStack>
                                </Td>
                                <Td display={{ base: "table-cell", md: "none" }}>{player.player_role}</Td>
                                <Td display={{ base: "none", md: "table-cell" }}>{player.player_role}</Td>
                                <Td display={{ base: "none", md: "table-cell" }}>{player.city}</Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>



                {/* Player Details Modal */}
                <Modal isOpen={detailModal.isOpen} onClose={detailModal.onClose} isCentered size="md">
                    <ModalOverlay />
                    <ModalContent rounded="lg" bg="gray.50">
                        <ModalHeader fontSize={{ base: "2xl", md: "3xl" }} color="teal.600" textAlign="center">
                            {selectedPlayer ? `${selectedPlayer.first_name} ${selectedPlayer.last_name}` : 'Player Details'}
                        </ModalHeader>
                        <ModalCloseButton size="lg" />
                        <ModalBody>
                            {selectedPlayer && (
                                <VStack spacing={5} align="center">
                                    <Image
                                        borderRadius="full"
                                        boxSize={{ base: "100px", md: "120px" }}
                                        src={selectedPlayer.avtar_img || 'https://cdn-icons-png.flaticon.com/512/149/149071.png'}
                                        alt={`${selectedPlayer.first_name} ${selectedPlayer.last_name}`}
                                        fallbackSrc='https://cdn-icons-png.flaticon.com/512/149/149071.png'
                                        border="4px"
                                        borderColor="teal.500"
                                    />
                                    <Box w="full">
                                        <Text fontSize="xl" fontWeight="semibold">Gender:</Text>
                                        <Text fontSize="lg" color={selectedPlayer.gender === 'Male' ? "blue.500" : "pink.500"}>
                                            {selectedPlayer.gender === 'Male' ? <Icon as={MdMale} w={6} h={6} mr={2} /> : <Icon as={MdFemale} w={6} h={6} mr={2} />}
                                            {selectedPlayer.gender}
                                        </Text>
                                    </Box>
                                    <Box w="full">
                                        <Text fontSize="xl" fontWeight="semibold">Contact:</Text>
                                        <HStack>
                                            <Icon as={MdPhoneInTalk} w={6} h={6} color="teal.500" />
                                            <Text fontSize="lg">{selectedPlayer.mobile_number}</Text>
                                        </HStack>
                                    </Box>
                                    <Box w="full">
                                        <Text fontSize="xl" fontWeight="semibold">Role:</Text>
                                        <Text fontSize="lg">{selectedPlayer.player_role}</Text>
                                    </Box>
                                    <Box w="full">
                                        <Text fontSize="xl" fontWeight="semibold">City:</Text>
                                        <Text fontSize="lg">{selectedPlayer.city}</Text>
                                    </Box>
                                </VStack>
                            )}
                        </ModalBody>
                        <ModalFooter>
                            <Button variant="ghost" colorScheme="teal" mr={3} onClick={detailModal.onClose}>
                                Close
                            </Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>


                <Modal isOpen={isOpen} onClose={onClose} fresh={fetchPlayers}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>Add Player</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            <UserForm />
                        </ModalBody>
                    </ModalContent>
                </Modal>
            </Card>
        </Box>
    );
};

export default Teams;