//eslint-disable-next-line
import axios from "axios";
import { logOut } from "commonFunctions/logout";
import { secretToken } from "./collection";

let baseURL = "https://api.mhealth.ai/";

const api = axios.create({
  baseURL,
  timeout: 6000000,
});

api.interceptors.request.use(
  async (config) => {
    console.log(config.url);
    if (config.url.includes("clients/")) {
        config.headers.Authorization = `Bearer ${secretToken}`;
    } else if (localStorage.getItem("token")) {
        config.headers.Authorization =`Bearer ${localStorage.getItem("token")}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
api.interceptors.response.use(
  function (resp) {
    return resp;
  },
  function (error) {
    if (error.response && error.response.status === 401) {
      console.log(error)
    }
    return Promise.reject(error);
  }
);
export default class ApiCalls {
  static get(url) {
    return new Promise((resolve, reject) => {
      return api
        .get(url)
        .then((res) => {
          resolve(res.data.response.responseData);
          
        })
        .catch((err) => {
          reject(err.data);
        });
    });
  }

  static post(url, payload) {
    return new Promise((resolve, reject) => {
      return api
        .post(url, payload)
        .then((res) => {
            resolve(res.data.responseData);
        })
        .catch((err) => {
          reject(err.data);
        });
    });
  }
  static put(url, payload) {
    return new Promise((resolve, reject) => {
      return api
        .put(url, payload)
        .then((res) => {
            resolve(res.data.responseData);
            console.log(res.data.responseData);
        })
        .catch((err) => {
          reject(err.data);
        });
    });
  }
  static delete(url, payload) {
    return new Promise((resolve, reject) => {
      return api
        .delete(url, payload)
        .then((res) => {
            resolve(res.data.responseData);
        })
        .catch((err) => {
          reject(err.data);
        });
    });
  }
}
