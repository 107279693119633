import React, { useState } from 'react';
import {
    Box,
    Text,
    Image,
    Button,
    Badge,
    VStack,
    HStack,
    useColorModeValue,
    IconButton,
    Tooltip,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    Spacer,
    Flex,
    ModalFooter,
    ModalCloseButton,
    Divider,
} from '@chakra-ui/react';
import { InfoIcon } from '@chakra-ui/icons';

const ChallengeCard = ({ challenge, onActionClick }) => {
    const [isModalOpen, setModalOpen] = useState(false);
    const currentDate = new Date();
    const registrationEndDate = new Date(challenge.registrationEndDate);
    const challengeStartDate = new Date(challenge.challengeStartDate);
    const challengeEndDate = new Date(challenge.challengeEndDate);  
    const now = new Date();
    const cardBg = useColorModeValue('green.200', 'green.700');
    const joinButtonColor = useColorModeValue('green.400', 'green.500');
    const buttonColor = challenge.registered ? 'red' : 'green';
    const buttonLabel = challenge.registered ? 'Unsubscribe' : 'Join';

    let daysToGo;
    let isRegistrationOver = currentDate > registrationEndDate;

    if (isRegistrationOver) {
        // Calculate the difference in days between the current date and the challenge start date
        const dayOfChallenge = Math.floor((currentDate - challengeStartDate) / (1000 * 60 * 60 * 24)) + 1;
        daysToGo = `Day ${dayOfChallenge} of Challenge`;
      } else {
        // Calculate the days to go for registration end
        daysToGo = `${Math.floor((registrationEndDate - currentDate) / (1000 * 60 * 60 * 24))} Days To Go`;
      }

    const openModal = () => setModalOpen(true);
    const closeModal = () => setModalOpen(false);

    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'short', day: 'numeric' };
        return new Date(dateString).toLocaleDateString('en-GB', options);
    };

    return (
        <><Box
            bg={cardBg}
            maxW="sm"
            borderWidth="1px"
            borderRadius="lg"
            overflow="hidden"
            p={2}
            m={2}
            boxShadow="lg"
            position="relative"
        >
            <Image
                src={challenge.eventChallengeBanner}
                alt={`Banner of ${challenge.challengeName}`}
                borderRadius="lg" />
            <VStack align="start" mt={-3}>
                <Flex w="full" justify="space-between" px={2}>
                    <Badge colorScheme="purple" variant="subtle">
                        Reward: {challenge.rewardMcoins}
                    </Badge>
                    <Badge colorScheme="orange" variant="solid">
                        Fees: {challenge.registrationMcoins}
                    </Badge>
                </Flex>
                <Box p={4} bg="rgba(255, 255, 255, 0.8)" borderRadius="lg" w="full">
                    <VStack align="start">
                        <Text fontSize="xl" fontWeight="bold" color="gray.700">
                            {challenge.challengeName}
                        </Text>
                        <HStack justify="space-between" w="full">
                            <Text fontSize="md" color="gray.600">
                                {daysToGo}
                            </Text>
                            <Tooltip label="More Info" hasArrow>
                                <IconButton
                                    icon={<InfoIcon />}
                                    variant="ghost"
                                    size="sm"
                                    onClick={openModal} />
                            </Tooltip>
                        </HStack>
                        <HStack justify="space-between" w="full">
                            <Text fontSize="sm" color="gray.500">
                            <strong>From :</strong> {formatDate(challenge.challengeStartDate)}
                            </Text>
                            <Text fontSize="sm" color="gray.500">
                            <strong>To :</strong> {formatDate(challenge.challengeEndDate)}
                            </Text>
                        </HStack>
                        {!challenge.registered && (
            <Button
                colorScheme="green"
                color="white"
                size="md"
                mt={3}
                onClick={() => onActionClick(challenge)}
            >
                Join
            </Button>
        )}

                    </VStack>
                </Box>
                <Spacer />
            </VStack>
        </Box><Modal isOpen={isModalOpen} onClose={closeModal} isCentered>
                <ModalOverlay />
                <ModalContent mx={4}>
                    <ModalHeader>{challenge.challengeName}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <VStack spacing={4} align="stretch">
                            <Image
                                src={challenge.eventChallengeBanner}
                                alt={`Banner of ${challenge.challengeName}`}
                                borderRadius="lg" />
                            <Text><b>Challenge Name:</b> {challenge.challengeName}</Text>
                            <Divider />
                            <Text><b>Description:</b> {challenge.challengeDescription}</Text>
                            <Text><b>Eligibility:</b> {challenge.challengeEligibility}</Text>
                            <Text><b>Rules:</b> {challenge.challengeRules}</Text>
                            <Text><b>Terms & Conditions:</b> {challenge.challengeTnc}</Text>
                            <Text><b>Registration Start Date:</b> {new Date(challenge.registrationStartDate).toLocaleDateString()}</Text>
                            <Text><b>Registration End Date:</b> {new Date(challenge.registrationEndDate).toLocaleDateString()}</Text>
                        </VStack>
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="blue" mr={3} onClick={closeModal}>
                            Close
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal></>
    );
};

export default ChallengeCard;
