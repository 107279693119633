import React from 'react';
import useCreditorValidity from 'isuserCreditor';
import TeamManagement from './playerView';
import Teams from './captainView';

// Assuming you're getting the mobile number from somewhere, e.g., localStorage or a global state
const mobileNumber = localStorage.getItem('mobile'); // Adjust as needed

const TeamConditionalComponent = () => {
  const isValidCreditor = useCreditorValidity(mobileNumber);

  return (
    <>
      {isValidCreditor ? <Teams /> : <TeamManagement />}
    </>
  );
};

export default TeamConditionalComponent;
