import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Card from 'components/card/Card';
import { SimpleGrid, Box, Image,VStack, Text, Badge, useColorModeValue, Spinner, Alert, AlertIcon,Center, ScaleFade  } from '@chakra-ui/react';

const Achievements = () => {
  const [achievements, setAchievements] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const cardBg = useColorModeValue("whiteAlpha.800", "whiteAlpha.200"); // For glassmorphism effect
  const textColor = useColorModeValue("gray.700", "gray.200");


  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const response = await axios.get('https://api.mhealth.ai/v1.0/getLeaderBoardAchievement?challengerZoneId=123', {
          headers: {
            'Accept': '*/*',
            'Accept-Language': 'en-US,en;q=0.9',
            'Access-Control-Allow-Headers': 'accept, content-type, x-access-token, x-requested-with',
            'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,OPTIONS',
            'Access-Control-Allow-Origin': '*',
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          }
        });
        if (response.data.response.responseCode === 0) {
          const sortedAchievements = Object.values(response.data.response.responseData).sort((a, b) => a.value - b.value);
          setAchievements(sortedAchievements);
        } else {
          console.error('Failed to fetch achievements:', response.data.responseMessage);
          setError('Failed to fetch achievements');
        }
      } catch (error) {
        console.error('Error fetching achievements:', error);
        setError('Error fetching achievements');
      }
      setIsLoading(false);
    };

    fetchData();
  }, []);

  if (isLoading) {
    return <Spinner size="xl" />;
  }

  if (error) {
    return (
      <Alert status="error">
        <AlertIcon />
        {error}
      </Alert>
    );
  }



  return (
    <Card bg={cardBg} borderRadius="3xl" overflow="hidden">
      <Center>
        <SimpleGrid columns={{ sm: 2, md: 3, lg: 3 }} spacing={2}>
          {achievements.map((achievement, index) => (
            <ScaleFade key={index} in={!isLoading} initialScale={0.9}>
                <VStack spacing={2}>
                  <Image
                    src={achievement.achievementIcon}
                    alt={`Achievement ${achievement.value}`}
                    borderRadius="xl"
                    boxSize="153.5px"
                    objectFit="contain"
                    m="auto"
                  />
                  <VStack spacing={1} align="stretch">
                    <Badge colorScheme="green" px={2} py={1} borderRadius="full">
                      Fitness
                    </Badge>
                  </VStack>
                </VStack>
            </ScaleFade>
          ))}
        </SimpleGrid>
      </Center>
    </Card>
  );
};

export default Achievements;
