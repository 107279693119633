import React, { useState, useEffect } from 'react';
import {
  Box, Table, Thead, Tbody,
  Tr, Th, Td, Image, Text, HStack, Icon, Center
} from '@chakra-ui/react';
import { MdInfo } from 'react-icons/md';
import axios from 'axios';
import Card from 'components/card/Card';

const TeamManagement = () => {
  const [players, setPlayers] = useState([]);
  const [apiError, setApiError] = useState(false); // State to track API errors or empty data

  const fetchPlayers = async () => {
    setApiError(false); // Reset error state before API call
    const token = localStorage.getItem('token');
    try {
      const response = await axios.get('https://go.mhealth.ai/api/USER/v1.0/getMyTeam', {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.data.userDetails && response.data.userDetails.length > 0) {
        setPlayers(response.data.userDetails.map(user => ({
          ...user,
          first_name: user.FirstName,
          last_name: user.LastName,
          avatar_img: user.AvtarImg || 'https://cdn-icons-png.flaticon.com/512/149/149071.png',
          player_role: user.PlayerRole,
        })));
      } else {
        setApiError(true);
      }
    } catch (error) {
      console.error("Failed to fetch players", error);
      setApiError(true);
    }
  };

  useEffect(() => {
    fetchPlayers();
  }, []);

  return (
    <Box pt={{ base: "130px", sm: "90px", md: "90px", xl: "90px" }} bg="#f8f9fa">
      <Card>
        {apiError || players.length === 0 ? (
          <Center flexDirection="column" mt="20">
            <Icon as={MdInfo} w={10} h={10} color="teal.500" />
            <Text fontSize="xl" mt="4">You are not part of any team yet!</Text>
          </Center>
        ) : (
          <Table variant="simple" colorScheme="gray" size="md">
            <Thead>
              <Tr>
                <Th>Player Name</Th>
                <Th display={{ base: "table-cell", md: "none" }}>Player Role</Th>
                <Th display={{ base: "none", md: "table-cell" }}>Player Role</Th>
              </Tr>
            </Thead>
            <Tbody>
              {players.map((player, index) => (
                <Tr key={index} cursor="pointer" _hover={{ bg: 'gray.100' }}>
                  <Td>
                    <HStack spacing={4}>
                      <Image
                        borderRadius='full'
                        boxSize={{ base: '30px', sm: '40px' }}
                        src={player.avatar_img}
                        alt={`${player.first_name} ${player.last_name}`}
                        fallbackSrc='https://cdn-icons-png.flaticon.com/512/149/149071.png'
                      />
                      <Text fontSize={{ base: "sm", md: "md" }}>{player.first_name} {player.last_name}</Text>
                    </HStack>
                  </Td>
                  <Td display={{ base: "table-cell", md: "none" }}>{player.player_role}</Td>
                  <Td display={{ base: "none", md: "table-cell" }}>{player.player_role}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        )}
      </Card>
    </Box>
  );
};

export default TeamManagement;
