import React, { useEffect, useState } from "react";
import {
  Box,
  Flex,
  Icon,
  Text,
  SimpleGrid,
  useColorModeValue,
  VStack,
  HStack,
  Center,
  Menu,
  MenuButton,
  MenuList,
  useDisclosure,
  MenuItem,
  Button,
} from "@chakra-ui/react";
import { ChevronDownIcon } from '@chakra-ui/icons';
import Card from "components/card/Card.js";
import ReactApexChart from "react-apexcharts";
import { UnlockIcon } from '@chakra-ui/icons';
import { FaWalking, FaBurn, FaRulerCombined } from 'react-icons/fa';
import { FaRunning, FaHeartbeat } from 'react-icons/fa';
import axios from "axios"
import Lottie from 'react-lottie';
import pace from "../../../../assets/pace.json"
import calorie from "../../../../assets/calorie.json"
import heart from "../../../../assets/heart.json"
import steps from "../../../../assets/steps.json"

import ConnectDataSourceModal from "./dataSource";

import Achievements from "./achievements";

export default function WalkDataDashboard() {
  const [rawWalkData, setRawWalkData] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [percentageChange, setPercentageChange] = useState(0);
  const [todaySteps, setTodaySteps] = useState(0);
  const [loading, setLoading] = useState(false)
  const [distance, setDistance] = useState("")
  const [motivationalText, setMotivationalText] = useState("");
  const [chartType, setChartType] = useState('bar');
  const [showConnectDataSourceButton, setShowConnectDataSourceButton] = useState(false);
  const [averagePace, setaveragePace] = useState('');
  const [heartpoints, setHeartPoints] = useState('');
  const [calories, setCalories] = useState('');
  const [selectedDataType, setSelectedDataType] = useState('Steps');
  const [chartOptions, setChartOptions] = useState({
    chart: {
      id: 'steps-over-time',
      toolbar: { show: false },
      zoom: { enabled: false },
      animations: {
        enabled: true,
        easing: 'easeinout',
        speed: 800,
        animateGradually: { enabled: true, delay: 150 },
        dynamicAnimation: { enabled: true, speed: 350 }
      }
    },
    dataLabels: {
      enabled: true,
      style: {
        colors: ['#333'],
        fontSize: '12px',
        fontFamily: 'Helvetica, Arial, sans-serif',
        fontWeight: 600,
      },
      background: {
        enabled: true,
        foreColor: '#fff',
        padding: 4,
        borderRadius: 2,
        borderWidth: 1,
        borderColor: '#333',
        opacity: 0.9,
      },
      offsetY: -5,
    },
    annotations: {},
    colors: ['#ADD8E6', '#F6AD55', '#68D391'],
    xaxis: {
      type: 'datetime',
      categories: [],
      labels: {
        format: 'dd MMM',
        style: {
          colors: '#333',
          fontSize: '10px',
        },
      },
      axisTicks: {
        show: true,
      },
      axisBorder: {
        show: true,
        color: '#333',
      },
    },
    tooltip: {
      enabled: true,
      x: { format: 'dd MMM yyyy' },
      y: {
        formatter: function (val) {
          return `${val} ${selectedDataType === 'Kilometers' ? 'km' : selectedDataType}`;
        },
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        horizontal: false,
        columnWidth: '60%',
      },
    },
    stroke: {
      show: true,
      width: 3,
      curve: 'smooth',
    },
    markers: {
      size: 5,
      colors: undefined,
      strokeColors: '#fff',
      strokeWidth: 2,
      strokeOpacity: 0.9,
      strokeDashArray: 0,
      fillOpacity: 1,
      discrete: [],
      shape: "circle",
      radius: 2,
      offsetX: 0,
      offsetY: 0,
      hover: {
        size: 8,
        sizeOffset: 3,
      },
    },
    grid: {
      borderColor: '#e7e7e7',
      strokeDashArray: 0,
      position: 'back',
      xaxis: { lines: { show: false } },
      yaxis: { lines: { show: true } },
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            height: 300,
          },
          legend: {
            position: 'bottom',
            offsetX: -10,
            offsetY: 0,
          },
        },
      },
    ],
  });


  const [chartSeries, setChartSeries] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const cardBg = useColorModeValue("white", "gray.700");
  const highlightColor = percentageChange > 0 ? "green.400" : "red.400";


  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await axios.get('https://api.mhealth.ai/v1.0/getDateWiseLeaderBoardData', {
          params: {
            challengerZoneIds: localStorage.getItem('selectedClub'),
          },
          headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem("token")}`,
          },
        });

        const responseData = response.data.response.responseData.challengerWiseLeaderBoard;
        if (responseData.length > 0) {
          const sortedDateWiseBoardData = responseData[0].dateWiseBoard.sort((a, b) => new Date(a.valueTillDate) - new Date(b.valueTillDate));
          const last7DaysData = sortedDateWiseBoardData.slice(-7);

          setRawWalkData(last7DaysData);
          const categories = last7DaysData.map(item => item.valueTillDate);
          const stepsData = last7DaysData.map(item => item.steps);


          setChartSeries([{ name: "Steps", data: stepsData }]);


          setChartOptions(prevOptions => ({
            ...prevOptions,
            xaxis: { ...prevOptions.xaxis, categories },
          }));

          if (last7DaysData.length >= 2) {
            console.log(last7DaysData);
            const latestData = last7DaysData[last7DaysData.length - 1];
            const previousData = last7DaysData[0];
            const change = ((latestData.steps - previousData.steps) / previousData.steps) * 100;
            const heartpoints = latestData.heartPoints;
            const todayCalories = latestData.calories;
            setHeartPoints(heartpoints);
            setCalories(todayCalories);
            setTodaySteps(latestData.steps);
            setaveragePace(latestData.averagePace);
            setDistance(latestData.value)
            setPercentageChange(change.toFixed(2));
            setMotivationalText(change > 0 ? "Great job! You're doing better than earlier in the week." : "A little effort makes a big difference! Let's try to move a bit more.");
          }

          setLoading(false);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);


  function parseDateToISO(dateStr) {
    const date = new Date(dateStr);
    return !isNaN(date.getTime()) ? date.toISOString().slice(0, 10) : null;
  }


  useEffect(() => {
    console.log(rawWalkData);
    if (rawWalkData.length > 0) {
      const categories = rawWalkData.map(data => parseDateToISO(data.valueTillDate))
        .filter(dateISO => dateISO !== null)
        .map(dateISO => dateISO.slice(0, 10));

      setChartOptions(prevOptions => ({
        ...prevOptions,
        xaxis: { ...prevOptions.xaxis, categories }
      }));

      let seriesData = [];
      switch (selectedDataType) {
        case 'Steps':
          seriesData = rawWalkData.map(data => parseInt(data.steps, 10));
          break;
        case 'Calories':
          seriesData = rawWalkData.map(data => parseInt(data.calories, 10));
          break;
        case 'Kilometers':
          seriesData = rawWalkData.map(data => parseFloat(data.value));
          break;
        default:
          break;
      }

      setChartSeries([{ name: selectedDataType, data: seriesData }]);
    }
  }, [rawWalkData, selectedDataType]);


  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  if (loading) {
    return <div>Loading...</div>
  }

  const MetricCard = ({ icon, title, value, unit, colorScheme, lottieAnimationData }) => {
    const textColor = useColorModeValue("gray.700", "white");
    const bg = useColorModeValue('rgba(255, 255, 255, 0.4)', 'rgba(255, 255, 255, 0.15)');

    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: lottieAnimationData,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    };

    return (


      <VStack
        backdropBlur="lg"
        bg={cardBg}
        textAlign="left"
        p={4}
        boxShadow="md"
        borderRadius="2xl"
        flex="1"
        align="left"
        spacing={2}
        sx={{
          backdropFilter: 'blur(10px)',
          WebkitBackdropFilter: 'blur(10px)',
        }}
      >
        <Flex justifyContent="space-between" alignItems="center" width="100%">
          <Text fontSize="xl" fontWeight="bold" color={textColor}>{title}</Text>
          <Icon as={icon} w={6} h={6} color={`${colorScheme}.400`} />
        </Flex>
        <Lottie options={defaultOptions} height={150} width={150} />
        <Text textAlign="left" fontSize="xl" fontWeight="bold" color={colorScheme}>{value} {unit}</Text> {/* Adjusted this line */}
      </VStack>
    );
  };

  return (
    <VStack spacing={4}>
      <Box w="full">
        <SimpleGrid columns={{ base: 2, md: 4 }} spacing={4}>
          <MetricCard icon={FaWalking} title="Steps" value={todaySteps.toLocaleString()} unit="steps" colorScheme="green" lottieAnimationData={steps} />
          <MetricCard icon={FaBurn} title="Calories" value={calories.toLocaleString()} unit="Kcal" colorScheme="orange" lottieAnimationData={calorie} />
          <MetricCard icon={FaHeartbeat} title="Heart" value={heartpoints.toLocaleString()} unit="points" colorScheme="red" lottieAnimationData={heart} />
          <MetricCard icon={FaRunning} title="Distance Covered" value={distance.toLocaleString()} unit="Km" colorScheme="blue" lottieAnimationData={pace} />
        </SimpleGrid>
      </Box>



      <Flex direction={{ base: "column", md: "row" }} w="full" gap={4}>
        {/* Chart Card with 60% width */}
        <Box flex="6">
          <Card bg={cardBg} p={4} boxShadow="xl" borderRadius="lg" position="relative">
            {showConnectDataSourceButton && (
              <Box position="absolute" top={0} left={0} right={0} bottom={0} bg="rgba(255, 255, 255, 0.1)" backdropFilter="blur(2px)" zIndex="overlay">
                <Center height="100%">
                  <Button colorScheme="blue" onClick={openModal}>Connect Data Source</Button>
                  <ConnectDataSourceModal isOpen={isModalOpen} onClose={closeModal} />
                </Center>
              </Box>
            )}
            <Flex justify="space-between" align="center" w="full">
              <Text fontSize="lg" fontWeight="bold" color={textColor}>Activity Analytics</Text>
              <Button size="sm" onClick={openModal} colorScheme="blue" leftIcon={<Icon as={UnlockIcon} color={'white'} />}>
                Authorize
              </Button>
              <ConnectDataSourceModal isOpen={isModalOpen} onClose={closeModal} />
            </Flex>

            <Flex justifyContent="center" mb={4} mt={4}>
              <Button size="xs" leftIcon={<Icon as={FaWalking} />} colorScheme={selectedDataType === 'Steps' ? 'blue' : 'teal'} mr={2} onClick={() => setSelectedDataType('Steps')}>
                Steps
              </Button>
              <Button size="xs" leftIcon={<Icon as={FaBurn} />} colorScheme={selectedDataType === 'Calories' ? 'blue' : 'teal'} mr={2} onClick={() => setSelectedDataType('Calories')}>
                Calories
              </Button>
              <Button size="xs" leftIcon={<Icon as={FaRulerCombined} />} colorScheme={selectedDataType === 'Kilometers' ? 'blue' : 'teal'} onClick={() => setSelectedDataType('Kilometers')}>
                Kilometers
              </Button>
            </Flex>

            <Box w="full">
              <ReactApexChart options={chartOptions} series={chartSeries} type={chartType} height={350} />
            </Box>
            {/* <Menu>
              <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>Time Range</MenuButton>
              <MenuList>
                <MenuItem>Today</MenuItem>
                <MenuItem>Last Week</MenuItem>
                <MenuItem>This Week</MenuItem>
                <MenuItem>This Month</MenuItem>
              </MenuList>
            </Menu> */}
          </Card>
        </Box>

        {/* Achievements Card with 40% width */}
        <Box flex="4">
          <Card bg={cardBg} p={4} boxShadow="xl" borderRadius="lg">
            <Flex justifyContent="space-between" alignItems="center">
              <Text fontSize="lg" fontWeight="bold" color={textColor}>Achievements</Text>
              <Text fontSize="sm" fontWeight={"bold"} color={textColor} _hover={{ cursor: "pointer", textDecoration: "underline" }}>
                View All
              </Text>
            </Flex>
            <Box mt={0}>
              <Achievements />
            </Box>
          </Card>
        </Box>

      </Flex>
    </VStack>
  );
}
