import React, { useState } from 'react';
import axios from 'axios';
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
  VStack,
} from '@chakra-ui/react';
import { useAlert } from 'context/alertContext';

const UserForm = (fresh) => {
  const [formData, setFormData] = useState({
    MobileNumber: '', // Initially as a string for input handling
    FirstName: '',
    LastName: '',
    MaxCredit: '', // Initially as a string for input handling
    IsActive: 1,
    PlayerRole: '',
  });
  const { showAlert } = useAlert();

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Handling full name split
    if (name === 'FullName') {
      const names = value.split(' ');
      const firstName = names[0];
      const lastName = names.slice(1).join(' '); // Handle middle name as part of last name
      setFormData(prevState => ({
        ...prevState,
        FirstName: firstName,
        LastName: lastName,
      }));
    } else {
      // Handling other inputs including converting to integer as needed
      setFormData(prevState => ({
        ...prevState,
        [name]: (name === 'MaxCredit' || name === 'IsActive') ? parseInt(value, 10) : value,
      }));
    }
  };

// Updated validateUser to return a boolean
const validateUser = async (mobileNumber) => {
  try {
    const response = await axios.get(
      'https://api.mhealth.ai/clients/validateUser', 
      {
        params: {
          countryCode: 91,
          mobileNumber: mobileNumber
        },
        headers: {
          'accept': '*/*',
          'Authorization': `Bearer VAP3sABNy-xCcJUvmvH1cWLeugP3VvzcGp2T5vD_6EA`, 
          'timeStamp': "" // Ensure you populate this if necessary
        }
      }
    );
    return response.data.response.status === 'ACTIVE';
  } catch (error) {
    console.error('Error validating user:', error);
    return false; // Assuming validation fails if there's an error
  }
}

// Function to send a WhatsApp message
const sendMessageOnWhatsApp = async (mobileNumber, firstName) => {
  const registrationLink = `https://mhealth.ai/signup?mobile=${mobileNumber}&redirect=%2FredirectToApp`;
  const role = "Spada Arena"
  const teamName = localStorage.getItem('firstname');
  const message = `Welcome to mHealth Sport Club.\n\nDear ${firstName},\n\nYou have been invited to Join Team ${teamName} in ${role} by ${teamName}.\n\nPlease click on the below link to complete registration: ${registrationLink}\n\nTeam mHealth.\n\nSupport : Call at +91-7982926669\n\n*#ChalteRaho*`;

  try {
    await axios.post(`https://api.mhealth.ai/clients/sendMessageOnWhatsapp?mobileNumber=${mobileNumber}`, {
      message: message,
      message_id: "",
      type: "text"
    }, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer VAP3sABNy-xCcJUvmvH1cWLeugP3VvzcGp2T5vD_6EA'
      }
    });
  } catch (error) {
    console.error('Error sending WhatsApp message:', error);
  }
}

const handleSubmit = async (e) => {
  e.preventDefault();
  const bearerToken = localStorage.getItem('token');
  const mappedOn = new Date().toISOString();

  const payload = {
    ...formData,
    MobileNumber: parseInt(formData.MobileNumber, 10),
    MaxCredit: 1,
    IDMstGameCreditor: parseInt(localStorage.getItem('creditorID'), 10),
    MappedBy: parseInt(localStorage.getItem('creditorID'), 10),
    MappedOn: mappedOn,
  };

  const isActiveUser = await validateUser(formData.MobileNumber);
  try {
    await axios.post('https://go.mhealth.ai/api/INTERNAL/v1.0/addUserMapping', payload, {
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Accept-Language': 'en-US,en;q=0.9',
        'Authorization': `Bearer ${bearerToken}`,
        'Content-Type': 'application/json',
      },
    });
    showAlert({
      title: "Success",
      description: "Player Added Successfully.",
      status: "success",
    });

    if (!isActiveUser) {
      await sendMessageOnWhatsApp(formData.MobileNumber, formData.FirstName);
      showAlert({
        title: "Notice",
        description: "Invitation sent.",
        status: "info",
      });
    }

  } catch (error) {
    showAlert({
      title: "Failed",
      description: `${formData.FirstName} is already in team or an error occurred.`,
      status: "error",
    });
  }
};



  return (
    <VStack as="form" onSubmit={handleSubmit} spacing={4}>
      <FormControl isRequired>
        <FormLabel htmlFor='MobileNumber'>Mobile Number</FormLabel>
        <Input
          id='MobileNumber'
          name='MobileNumber'
          type='tel'
          value={formData.MobileNumber}
          onChange={handleChange}
          maxLength={10}
          pattern="\d*"
        />
      </FormControl>
      <FormControl isRequired>
        <FormLabel htmlFor='FullName'>Full Name</FormLabel>
        <Input
          id='FullName'
          name='FullName'
          value={`${formData.FirstName} ${formData.LastName}`}
          onChange={handleChange}
          placeholder="First Last"
        />
      </FormControl>
      <FormControl isRequired>
        <FormLabel htmlFor='PlayerRole'>Player Role</FormLabel>
        <Select 
          id='PlayerRole' 
          name='PlayerRole' 
          value={formData.PlayerRole} 
          onChange={handleChange}>
          <option value="">Select Role</option>
          <option value="Batsman">Batsman</option>
          <option value="Bowler">Bowler</option>
          <option value="All Rounder">All Rounder</option>
          <option value="Fielder">Fielder</option>
        </Select>
      </FormControl>
      <Button type='submit' colorScheme='blue'>Submit</Button>
    </VStack>
  );
};

export default UserForm;
