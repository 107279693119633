import {
  Box,
  Flex,
  SimpleGrid,
  useColorModeValue,
  Text,
} from "@chakra-ui/react";
import React, { useState, useEffect, useCallback } from 'react';
import Card from "components/card/Card";
import ComplexTable from "views/admin/default/components/ComplexTable";
import TotalSpent from "views/admin/default/components/TotalSpent";
import {
  columnsDataComplex,
} from "views/admin/default/variables/columnsData";
import tableDataComplex from "views/admin/default/variables/tableDataComplex.json";
import ChallengeCard from "./components/stepChallengesCard";
import axios from "axios";
import { useAlert } from "context/alertContext";
import Achievements from "./components/achievements";

export default function UserReports() {
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const [challenges, setChallenges] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { showAlert } = useAlert();

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`https://api.mhealth.ai/v1.0/getUserEventChallengesByEventId?eventId=${localStorage.getItem('selectedClub')}`, {
        headers: {
          'Accept': '*/*',
          'Accept-Language': 'en-US,en;q=0.9',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        }
      });
      setChallenges(response?.data?.response?.responseData || []);
    } catch (error) {
      console.error(error);
      showAlert({
        title: 'Error fetching challenges',
        description: error.message,
        status: 'error',
      });
    }
    setIsLoading(false);
  }, [showAlert]);

  useEffect(() => {
    const baseUrl = window.location.href.split('?')[0];
    window.history.replaceState(null, null, baseUrl);
    fetchData();
  }, [fetchData]);

  const registerForEvent = async (challengeId) => {
    setIsLoading(true);
    try {
      const response = await axios.post('https://api.mhealth.ai/v1.0/createEventChallengeRegistration', {
        eventChallengeId: challengeId,
      }, {
        headers: {
          'Accept': '*/*',
          'Accept-Language': 'en-US,en;q=0.9',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        }
      });
      showAlert({
        title: 'Registration successful',
        description: response.data.message,
        status: 'success',
      });
      fetchData();
    } catch (error) {
      console.error(error);
      showAlert({
        title: 'Registration failed',
        description: error.response?.data?.message || error.message,
        status: 'error',
      });
    }
    setIsLoading(false);
  };


  return (
    <Box pt={{ base: "130px", sm:"80px", md: "80px", xl: "80px" }}>

      <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap='10px' mb='20px'>
        <TotalSpent />
      </SimpleGrid>
      <Card>
        <Flex justify="space-between" align="center" mb={3}>
          <Text
            color={textColor}
            fontSize="22px"
            fontWeight="700"
            lineHeight="100%"
          >
            Stepathon Challenges
          </Text>
        </Flex>
        <SimpleGrid columns={{ base: 1, sm: 1, md: 2, lg: 3 }} spacing="20px">
          {challenges.map((challenge) => (
            <ChallengeCard key={challenge.id} challenge={challenge} onActionClick={() => registerForEvent(challenge.id)} />
          ))}
        </SimpleGrid>
      </Card>
      <Box pt={{ base: "20px", md: "20px", xl: "20px" }}>
      <Card>
        <ComplexTable
          columnsData={columnsDataComplex}
          tableData={tableDataComplex}
        /> 
      </Card>
      </Box>
    </Box>
  );
}
