import React, { useEffect, useState } from "react";
import {
  Menu,
  MenuButton,
  Text,
  MenuList,
  MenuItem,
  Flex,
  Spacer,
  Button,
  useColorModeValue,
} from "@chakra-ui/react";
import { getAllEvents } from "api/collection";
import ApiCalls from "api/customAxios";
import { urlPrefix } from "api/collection";
import { FaChevronRight, FaCheckCircle } from "react-icons/fa";
import { useHistory } from "react-router-dom";

const CommunitySwitcher = () => {
  const [communities, setCommunities] = useState([]);
  const [selectedCommunity, setSelectedCommunity] = useState(
    parseInt(localStorage.getItem("selectedClub")) || 10
  );


  const history = useHistory();
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("#E6ECFA", "rgba(135, 140, 189, 0.3)");
  const hoverColor = useColorModeValue("brand.400", "brand.300");

  useEffect(() => {
    ApiCalls.get(
      `${urlPrefix}${getAllEvents}?others=walkathon21&userId=${localStorage.getItem(
        "userid"
      )}`
    )
      .then((res) => {
        setCommunities(res.events);
      })
      .catch((error) => console.error("Error fetching communities:", error));
  }, []);

  const handleCommunityChange = (id) => {
    setSelectedCommunity(id);
    localStorage.setItem("selectedClub", id.toString());
    // window.location.reload();
    history.push("/");

  };

  const selectedCommunityName = () => {
    const foundCommunity = communities.find(
      (community) => community.id === selectedCommunity
    );
    return foundCommunity ? foundCommunity.challengeName : "Select Community";
  };
  return (
    <Menu>
      <MenuButton
        as={Button}
        p="4px"
        fontSize="sm"
        colorScheme="brand"
        w="100%"
        justifyContent="space-between"
        rounded="3xl"
        rightIcon={<FaChevronRight color="white" />}
      >
        <Text>{selectedCommunityName()}</Text>
      </MenuButton>
      <MenuList
        boxShadow="md"
        border="1px solid"
        borderColor={borderColor}
        mt="0"
        w="100%"
        p="4"
        maxH="300px" // Set a maximum height
        overflowY="auto" // Enable vertical scrolling
      >
        {communities.map((community) => (
          <MenuItem
            key={community.id}
            onClick={() => handleCommunityChange(community.id)}
            color={selectedCommunity === community.id ? hoverColor : textColor}
            _hover={{ bg: hoverColor, color: "white" }}
            fontSize="sm"
            p="10px"
            transition="background 0.2s"
          >
            <Flex alignItems="center">
              <Text mr={2}>{community.challengeName}</Text>
              <Spacer />
              {selectedCommunity === community.id && <FaCheckCircle color={hoverColor} />}
            </Flex>
          </MenuItem>
        ))}
      </MenuList>

    </Menu>
  );
};

export default CommunitySwitcher;
