import React, { useState, useEffect } from 'react';
import {
    Box,
    IconButton,
    Input,
    InputGroup,
    InputRightElement,
    Text,
    VStack,
    Flex,
    Heading,
    Icon,
    Tooltip,
    useClipboard,
    Badge,
} from '@chakra-ui/react';
import { FaCopy, FaFacebook, FaTwitter, FaLinkedin, FaWhatsapp } from 'react-icons/fa';
import { useAlert } from 'context/alertContext';
import axios from 'axios';
import { FiUser } from 'react-icons/fi';

const ReferralComponent = () => {
    const [referrals, setReferrals] = useState([]);
    const [referralCode, setReferralCode] = useState('');
    const shareMessage = encodeURIComponent(`${localStorage.getItem('firstname')} is inviting you to join mHealth sports club where you can pay and play cricket games,  Join Team for Faceoff matches and more. Use my exclusive code ${referralCode} and get 250 mCoins.Join now - https://mhealth.ai/signup?code=${referralCode}&redirect=%2FredirectToApp`);
    const copyMessage = `${localStorage.getItem('firstname')} is inviting you to join mHealth sports club where you can pay and play cricket games,  Join Team for Faceoff matches and more. Use my exclusive code ${referralCode} and get 250 mCoins.Join now - https://mhealth.ai/signup?code=${referralCode}&redirect=%2FredirectToApp`

    const { hasCopied, onCopy } = useClipboard(copyMessage);
    const { showAlert } = useAlert();

    useEffect(() => {
        const token = localStorage.getItem('token');
        const authHeader = `Bearer ${token}`;
        const getReferralCode = async () => {
            try {
                const response = await fetch('https://go.mhealth.ai/api/INTERNAL/v1.0/referral/getReferralCode', {
                    method: 'GET',
                    headers: {
                        'Authorization': authHeader,
                    },
                });
                const data = await response.json();

                if (data && data.referralCode) {
                    setReferralCode(data.referralCode);
                } else {
                    await generateReferralCode();
                }
            } catch (error) {
                showAlert({
                    title: 'Error',
                    description: 'There was an error fetching the referral code.',
                    status: 'error',
                });
            }
        };

        const generateReferralCode = async () => {
            try {
                const generateResponse = await fetch('https://go.mhealth.ai/api/INTERNAL/v1.0/referral/generateReferralCode', {
                    method: 'POST',
                    headers: {
                        'Authorization': authHeader,
                        'Content-Type': 'application/json',
                    },
                });

                if (generateResponse.ok) {
                    getReferralCode();
                } else {
                    showAlert({
                        title: 'Error',
                        description: 'There was an error generating the referral code.',
                        status: 'error',
                    });
                }
            } catch (error) {
                showAlert({
                    title: 'Error',
                    description: 'There was an error generating the referral code.',
                    status: 'error',
                });
            }
        };

        getReferralCode();
    }, []);


    useEffect(() => {
        const token = localStorage.getItem('token');
        const authHeader = `Bearer ${token}`;
        // const authHeader = `Bearer l2e4aIhnMQ-4MVZTCKtWgEbXJTdkAYwQdnDcPNpIYYI`;

        const fetchReferrals = async () => {
            try {
                const response = await axios.get('https://go.mhealth.ai/api/PUBLIC/v1.0/referral/getReferralDetailsByUserID/', {
                // const response = await axios.get('http://localhost:8080/api/PUBLIC/v1.0/referral/getReferralDetailsByUserID/', {
                    headers: {
                        'Authorization': authHeader,
                    },
                });
                const data = response.data;

                if (data && data.data) { // Adjusting based on the provided API response structure
                    setReferrals(data.data);
                }
            } catch (error) {
                showAlert({
                    title: 'Error',
                    description: 'There was an error fetching the referrals.',
                    status: 'error',
                });
            }
        };

        fetchReferrals();
    }, []);

    const handleCopy = () => {
        onCopy();
        showAlert({
            title: 'Copied',
            description: 'Link Copied To Clipboard',
            status: 'success',
        });
    };


    return (
        <Box p={5} borderRadius="lg" bg="white">
            <VStack spacing={4} align="stretch">
                <Heading size="md" textAlign="center">Invite Friends & Earn Rewards</Heading>
                <Text fontSize="sm" textAlign="center" color="gray.600">
                    Get up to ₹100 when a new user plays their first match. Both you and your friend earn rewards!
                </Text>
                <Flex justifyContent="center" alignItems="center">
                    <Text>Your Referral Code:</Text>
                    <Badge ml={2} fontSize="md" colorScheme='green'>{referralCode}</Badge>
                </Flex>
                <Flex justifyContent="space-around">
                    <Tooltip label="Share on Twitter">
                        <IconButton size='lg' colorScheme='twitter' icon={<FaTwitter />} variant="ghost" aria-label="Share on Twitter" onClick={() => window.open(`https://twitter.com/intent/tweet?text=${shareMessage}`, '_blank')} />
                    </Tooltip>
                    <Tooltip label="Share on Facebook">
                        <IconButton size='lg' colorScheme='facebook' icon={<FaFacebook />} variant="ghost" aria-label="Share on Facebook" onClick={() => window.open(`https://www.facebook.com/sharer/sharer.php?u=https://mhealth.ai/signup?code=${referralCode}&quote=${shareMessage}`, '_blank')} />
                    </Tooltip>
                    <Tooltip label="Share on LinkedIn">
                        <IconButton size='lg' colorScheme='linkedin' icon={<FaLinkedin />} variant="ghost" aria-label="Share on LinkedIn" onClick={() => window.open(`https://www.linkedin.com/shareArticle?mini=true&url=https://mhealth.ai/signup?code=${referralCode}&summary=${shareMessage}`, '_blank')} />
                    </Tooltip>
                    <Tooltip label="Share on WhatsApp">
                        <IconButton size='lg' colorScheme='whatsapp' icon={<FaWhatsapp />} variant="ghost" aria-label="Share on WhatsApp" onClick={() => window.open(`https://wa.me/?text=${shareMessage}`, '_blank')} />
                    </Tooltip>
                    <Tooltip label="Copy Link">
                        <IconButton size='lg' colorScheme='blue' icon={<FaCopy />} variant="ghost" onClick={handleCopy} aria-label="Copy referral code" />
                    </Tooltip>
                </Flex>
                <Text mt={4} decoration="underline" fontSize="lg" fontWeight="semibold" textAlign="center">Your Referrals</Text>
                {referrals.length > 0 ? (
                    referrals.map((referral, index) => (
                        <Flex key={index} p={3} align="center" justify="space-between" borderWidth="1px" borderRadius="lg" borderColor="gray.200">
                            <Flex align="center">
                                <Icon as={FiUser} mr={2} color="gray.500" />
                                <Text fontWeight="bold">{`${referral.first_name} ${referral.last_name}`}</Text>
                                <Text ml={4} color="gray.500">{referral.city || "No city specified"}</Text>
                            </Flex>
                            <Flex align="center">
                                <Text fontSize="sm">Earned: ₹{referral.mcoins_earned_by_referred || "50"}</Text>
                                <Tooltip label={referral.referral_status === "credited" ? "Reward credited" : "Pending credit"} hasArrow>
                                    <Badge ml={2} size="sm" fontSize="0.8em" p={1} colorScheme={referral.referral_status === "credited" ? "green" : "yellow"}>
                                        {referral.referral_status.charAt(0).toUpperCase() + referral.referral_status.slice(1)}
                                    </Badge>
                                </Tooltip>
                            </Flex>
                        </Flex>
                    ))
                ) : (
                    <Text textAlign="center" mt={2}>No Referrals Yet</Text>
                )}
            </VStack>
        </Box>
    );
};

export default ReferralComponent;
