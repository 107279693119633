import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import {
  Flex,
  Table,
  Progress,
  Icon,
  Tbody,
  Td,
  Image,
  Badge,
  Box,
  Text,
  HStack,
  Tooltip,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from '@chakra-ui/react';
import { useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import moment from 'moment';
import { FaRunning, FaCity, FaTrophy, FaCalendarDay, FaRoad, FaUserFriends, FaChartLine } from 'react-icons/fa';

const ColumnsTable = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.get('https://api.mhealth.ai/v1.0/getLeaderBoardData?challengerZoneId=123', {
        headers: {
          'Authorization': 'Bearer fxuPnLdhwirty0Bd_PRrzgNY6PN8pl7EGIc_zDvZpo4',
          // Include other necessary headers
        },
      });
      if (response.data.response && response.data.response.responseCode === 0) {
        // Extract parts of the data
        const { challengerWiseLeaderBoard } = response.data.response.responseData;
        const { sessionUserRank, pinUserRank, rankWiseBoard } = challengerWiseLeaderBoard[0];

        // Prepare combined data, ensuring no duplication of session user in pinUserRank
        const combinedData = [sessionUserRank, ...pinUserRank.filter(user => user.userId !== sessionUserRank.userId), ...rankWiseBoard];

        setData(combinedData);
      }
    };
    fetchData();
  }, []);


  const columns = useMemo(
    () => [
      {
        Header: () => <HStack spacing="2"><Icon as={FaUserFriends} /><Text>Rank</Text></HStack>,
        accessor: 'rank',
        Cell: ({ row }) => (
          <Box position="relative">
            <Image src={row.original.avtarImg || "https://upload.wikimedia.org/wikipedia/commons/8/89/Portrait_Placeholder.png"} borderRadius="full" boxSize="40px" alt="Avatar" />
            <Badge
              display="flex"
              justifyContent="center"
              alignItems="center"
              fontSize="0.7em"
              colorScheme="purple"
              position="absolute"
              bottom="0"
              left="0"
              width="20px"
              height="20px"
              borderRadius="full"
              padding="0"
              border="2px solid white"
            >
              {row.original.rank}
            </Badge>
          </Box>
        ),
      },
      {
        Header: () => <HStack spacing="2"><Icon as={FaTrophy} /><Text>Name</Text></HStack>,
        accessor: 'userName',
        Cell: ({ value }) => <Text fontSize="md" fontWeight="bold">{value}</Text>,
      },
      {
        Header: () => <HStack spacing="2"><Icon as={FaRunning} /><Text>Achievement</Text></HStack>,
        accessor: 'achievementIcon',
        Cell: ({ row }) => (
          <Flex gap="2" align="center">
            {row.original.scount && <Image src={row.original.scount} boxSize="25px" alt="Level" />}
            {row.original.achievementIcon && <Image src={row.original.achievementIcon} boxSize="25px" alt="Achievement" />}
          </Flex>
        ),
      },
      {
        Header: () => <HStack spacing="2"><Icon as={FaCity} /><Text>City</Text></HStack>,
        accessor: 'city',
        Cell: ({ value }) => <Text fontSize="sm">{value}</Text>,
      },
      {
        Header: () => <HStack spacing="2"><Icon as={FaCalendarDay} /><Text>Overview</Text></HStack>,
        id: 'activityOverview',
        accessor: (row) => ({
          date: `On ${moment(row.valueTillDate).format('D MMM')}`,
          distance: `${row.lastDistanceCovered ? `${row.lastDistanceCovered.toFixed(2)} Km` : 'N/A'}`,
          activeDays: `${row.totalParticipationDays} days`,
        }),
        Cell: ({ value }) => (
          <Box>
            <Text fontSize="sm">{value.date} {value.distance}</Text>
            <Text fontSize="xs" color="gray.500">{value.activeDays} active</Text>
          </Box>
        ),
      },
      {
        Header: () => <HStack spacing="2"><Icon as={FaChartLine} /><Text>Metrics</Text></HStack>,
        id: 'performanceMetrics',
        accessor: (row) => ({
          totalKm: `Total: ${row.value ? `${row.value.toFixed(2)} Km` : 'N/A'}`,
          avgKm: `Avg: ${row.averageDistanceCovered ? `${row.averageDistanceCovered.toFixed(2)} Km` : 'N/A'}`,
          lead: `Lead: ${row.leadBy ? `${row.leadBy.toFixed(2)}` : 'N/A'}`,
        }),
        Cell: ({ value }) => (
          <Flex direction="column">
            <Text fontSize="sm">{value.totalKm}</Text>
            <Text fontSize="sm">{value.avgKm}</Text>
            <Text fontSize="sm" color="green.500">{value.lead}</Text>
          </Flex>
        ),
      },
    ],
    []
  );

  const tableInstance = useTable({ columns, data });

  return (
    <Flex direction="column" w="100%" overflowX="auto">
      <Table {...tableInstance.getTableProps()} variant="simple">
        <Thead>
          {tableInstance.headerGroups.map(headerGroup => (
            <Tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <Th {...column.getHeaderProps()}>{column.render('Header')}</Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...tableInstance.getTableBodyProps()}>
          {tableInstance.rows.map(row => {
            tableInstance.prepareRow(row);
            return (
              <Tr {...row.getRowProps()}>
                {row.cells.map(cell => (
                  <Td {...cell.getCellProps()}>{cell.render('Cell')}</Td>
                ))}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </Flex>
  );
};

export default ColumnsTable;