import React from 'react';
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom';
import AuthLayout from 'layouts/auth';
import AdminLayout from 'layouts/admin';
import RtlLayout from 'layouts/rtl';
import 'assets/css/App.css';
import ReactDOM from 'react-dom';
import { ChakraProvider } from '@chakra-ui/react';
import theme from 'theme/theme';
import { ThemeEditorProvider } from '@hypertheme-editor/chakra-ui';
import { AlertProvider } from 'context/alertContext';
import GlobalAlert from 'components/globalUIComponents/alert';
import { AuthProvider, useAuth } from 'context/authContext';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AuthenticationListener } from 'context/authContext';
import useCreditorValidity from 'isuserCreditor';
import Teams from 'views/admin/Team';


// ProtectedRoute component
const ProtectedRoute = ({ component: Component, ...rest }) => {
  const { isAuthenticated } = useAuth();
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: '/auth', state: { from: props.location } }} />
        )
      }
    />
  );
};



const App = () => {
  const isuserCreditor= useCreditorValidity();
  
  return (
    <ChakraProvider theme={theme}>
      <AlertProvider>
        <GlobalAlert />
        <ThemeEditorProvider>
          <AuthProvider>
            <HashRouter>
            <AuthenticationListener />
              <Switch>
                <Route path="/auth" component={AuthLayout} />
                <ProtectedRoute path="/admin" component={AdminLayout} />
                {isuserCreditor && <Route path="/Team" component={Teams} />}
                <Route path="/rtl" component={RtlLayout} />
                <Route
                  exact
                  path="/"
                  render={() => <Redirect to="/admin" />}
                />
              </Switch>
            </HashRouter>
          </AuthProvider>
        </ThemeEditorProvider>
      </AlertProvider>
    </ChakraProvider>
  );
};

ReactDOM.render(
  <AuthProvider>
    <App />
  </AuthProvider>,
  document.getElementById('root')
);
